import { VisionTranslations } from './types';

export const enTranslations: VisionTranslations = {
    title: "OUR VISION",
    paragraphs: {
        challenge: "As healthcare professionals, we found limited scenarios and lack of adaptability limited the effectiveness of traditional simulation training. We experienced firsthand how inadequate feedback failed to properly prepare healthcare professionals for the realities of practice.",
        belief: "We believed simulation training should enhance clinical skills and decision-making through collaborative learning - but most platforms failed to provide this.",
        solution: "We created Clinical Metrics to address these challenges and provide a dynamic, adaptive, and data-driven approach to medical simulation training. See examples of our realistic medical simulations in our videos."
    },
    video: {
        title: "Watch our intro video",
        alt: "Our Vision"
    }
};