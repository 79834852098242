import styled from "@emotion/styled";

export const PrivacyPolicyModalContainer = styled.div`
    width: 100%;
    gap: 1rem;
    color: #000000;
    position: relative;
    padding: 2rem 1.5rem;
    border-radius: 0.75rem;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom:15%;
    @media (min-width: 768px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media (max-width: 1024px) {
        width:100%
    }
    font-family: "Helvetica", Arial, sans-serif;
`;

export const PrivacyPolicyModalContentContainer = styled.div`
    overflow-y: auto;
    max-height: 35em;
    @media (max-width: 1023px) {
       padding-bottom:10%;
    }
`;

export const PrivacyPolicyModalTitle = styled.h1`
    font-size: 22px;
    margin-top: 5px;
    margin-right:20px;
`;
export const PrivacyPolicyModalSubtitle = styled.h2`
    font-size: 20px;
    margin-top: 5px;
`;
export const PrivacyPolicyModalParagraph = styled.p`
    font-size: 15px;
    font-weight: 400;
    margin: 1rem 0px;
    line-height: 1.5;
    margin-right:20px;
    text-wrap:wrap;
    
`;
export const PrivacyPolicyModalUnorderList = styled.ul`
    line-height: 1.5;
`;

export const PrivacyPolicyModalList = styled.li`
    font-size: 15px;
    line-height: 1.5;
    font-weight: 400;
`;

export const PrivacyPolicyModalBoldText = styled.span`
    font-weight: 700;
`;

export const PrivacyPolicyModalTable = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #dee2e6;
    width: 100%;
    margin-bottom: 1em;
    @media (max-width: 1023px) {
        overflow-x:auto;
        display:block;
    }
`;
export const PrivacyPolicyModalTableRow = styled.tr`
    
`;
export const PrivacyPolicyModalTableHead = styled.th`
    padding: 0.75em;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
`;
export const PrivacyPolicyModalTableData = styled.td`
    padding: 0.75em;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
`;

export const PrivacyPolicyModalTableBox = styled.div`
    margin-right:20px;
    @media (max-width: 1023px) {
            width: 93%;
            overflow: hidden;
    }
    `;

export const PrivacyPolicyModalLink = styled.a`
    font-size: 15px;
    color: #0000ee;
    margin-bottom: 20px;
`;

export const PrivacyPolicyModalBtns = styled.div`
    display:flex;
    gap:10px;
`;

export const PrintableArea = styled.div`
    padding: 15px;
`;