import { ComponentProps } from "react";
import { Modal } from "../Modal";
import {
  ResetPasswordModalContainer,
  ResetPasswordModalContentContainer,
  ResetPasswordModalTitle,
  ResetPasswordModalContentWrapper,
  Form,
  Button,
  SubTitle,
} from "./ResetPassword.styles";

// Language Context
import { useLanguage } from "../../../core/translations/LanguageContext";

// Translations
import { ResetPasswordModalTranslations } from "./translations/types";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { SupportedLanguage } from "../../../core/translations/supportedLanguages";

const TRANSLATIONS: Record<SupportedLanguage, ResetPasswordModalTranslations> =
  {
    en: enTranslations,
    de: deTranslations,
  };
const ResetPasswordModal = (
  props: Omit<ComponentProps<typeof Modal>, "children">
) => {
  const { language } = useLanguage();
  //@ts-ignore
  const translations = TRANSLATIONS[language];
  return (
    <Modal {...props}>
      <ResetPasswordModalContainer>
        <ResetPasswordModalContentContainer>
          <ResetPasswordModalContentWrapper>
            <Form>
              <ResetPasswordModalTitle>
                {translations.title}
              </ResetPasswordModalTitle>
              <SubTitle>{translations.subTitle}</SubTitle>
              <Button>{translations.confirm}</Button>
            </Form>
          </ResetPasswordModalContentWrapper>
        </ResetPasswordModalContentContainer>
      </ResetPasswordModalContainer>
    </Modal>
  );
};

export default ResetPasswordModal;
