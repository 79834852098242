// External imports
import React from 'react';

// Internal imports
import Footer from "../footer/Footer";
import Header from "./Header";
import { MainPageContent, MainPageGlobalStyles } from "./MainPageLayout.styles";
import { ModalRenderer } from "./ModalRenderer";

/**
 * Props for the main page layout component
 * @property children - React nodes to be rendered within the layout
 */
interface MainPageLayoutProps {
    children: React.ReactNode;
}

/**
 * Main page layout component that provides the basic structure for pages
 * Includes header, footer, and modal rendering capabilities

 * @param children - React nodes to be rendered within the layout
 */
const MainPageLayout: React.FC<MainPageLayoutProps> = ({ children }) => {
    return (
        <MainPageGlobalStyles>
            <Header loggedIn={false} />
            <MainPageContent>{children}</MainPageContent>
            <Footer />
            <ModalRenderer />
        </MainPageGlobalStyles>
    );
};

export default MainPageLayout;