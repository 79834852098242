import styled from "@emotion/styled";

export const ModalContainer = styled.div`
    display: grid;
    overflow-y: auto;
    position: fixed;
    top: 0;
    left: 0;
    padding: 2.5rem;
    place-items: center;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.25);
    z-index: 1000;
    @media (max-width: 1024px) {
        padding: 0;
    }
`;

export const ModalContent = styled.div<{ width?: string }>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${(props) => props.width || "80%"};
    display: flex;
    justify-content: center;
    align-items: center;
`;
