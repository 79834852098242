import { UserSession } from '../../ServerApiLayer/VOne/auth/login';
import { UserData, convertUserSession } from "./interface";


// Function signature type
export type SetFromApiDataType = (apiData: UserSession, jwt: string, refreshToken: string) => void;


/*
Stores the user session data in localStorage directly from the login api call.

@apiData: The UserSession data from the login api call.
@jwt: The JWT token to be sent in the header of requests requiring authentication.
@refreshToken: To be sent to request a new JWT token when the JWT token expires.
 */
export function setFromApiData(apiData: UserSession, jwt: string, refreshToken: string): void {
    const userData: UserData = {
        jwt: jwt,
        refreshToken: refreshToken,
        userSession: convertUserSession(apiData)
    };
    setUserSessionData(userData);
}


/*
Updates the user session data in localStorage.

@newUserData: The new UserData to be stored in localStorage.
 */
export function updateUserSessionData(newUserData: UserSession): void {
    let userSessionData = getUserSessionData();
    if (!userSessionData) {
        console.log('No user session data found in localStorage');
    }
    else {
        userSessionData.userSession = convertUserSession(newUserData);
        setUserSessionData(userSessionData);
    }
}


/*
Sets the user session data in localStorage.

@userData: The UserData to be stored in localStorage.

@returns: void
 */
export function setUserSessionData(userData: UserData): void {
    localStorage.setItem('userSessionData', JSON.stringify(userData));
}


/*
Gets the user session data from localStorage.

@returns: The UserData from localStorage.
 */
export function getUserSessionData(): UserData | null {
    const dataString = localStorage.getItem('userSessionData');

    if (!dataString) {
        return null;
    }

    try {
        const unwrappedUserData: UserData = JSON.parse(dataString);

        // Convert string dates to Date objects
        unwrappedUserData.userSession.dateCreated = new Date(unwrappedUserData.userSession.dateCreated);
        unwrappedUserData.userSession.tokenExpires = new Date(unwrappedUserData.userSession.tokenExpires);
        unwrappedUserData.userSession.refreshTokenExpires = new Date(unwrappedUserData.userSession.refreshTokenExpires);

        return unwrappedUserData;
    } catch (error) {
        console.error('Error parsing user data from local storage:', error);
        return null;
    }
}


/*
Gets the JWT token from localStorage.

@returns: The JWT token from localStorage (if null the token and refresh token have expired).
 */
export function getJwt(): string | null {
    const unwrappedUserData = getUserSessionData();

    if (!unwrappedUserData) {
        return null;
    }
    const now = new Date();

    if (unwrappedUserData.userSession.refreshTokenExpires > now) {
        return unwrappedUserData.jwt;
    } else {
        // TODO => try and refresh the token if the refresh token has not expired
        wipeUserSessionData();
        return null; // Token has expired
    }
}


/*
Gets a simple JWT token from localStorage.
 */
export function getSimpleJwt(): string | null {
    let token = localStorage.getItem("token");
    return token;
}


export function setSimpleJwt(token: string): void {
    localStorage.setItem("token", token);
}


/*
Wipes the user session data from localStorage.
 */
export function wipeUserSessionData(): void {
    localStorage.removeItem('userSessionData');
}


export type GetRoleType = () => string | null;

/*
get the role of the user from the user session data in localStorage.
 */
export function getUserRole(): string | null {
    const unwrappedUserData = getUserSessionData();

    if (!unwrappedUserData) {
        return null;
    }

    if (!unwrappedUserData.userSession.role) {
        console.error('No role found in user session data');
        return null;
    }

    return unwrappedUserData.userSession.role;
}