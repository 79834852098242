import React from "react";
import ViewUsersTable from "./components/ViewUsersTable/ViewUsersTable";
import MainPageLayout from "../../components/header/MainPageLayout";
import { PageTitle, PageTitleSection, TableCard } from "./ViewUsersPage.styles";
// Language Context
import { useLanguage } from "../../core/translations/LanguageContext";

// Translations
import { UsersPageTranslations } from "./translations/types";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { SupportedLanguage } from "../../core/translations/supportedLanguages";

const TRANSLATIONS: Record<SupportedLanguage, UsersPageTranslations> = {
  en: enTranslations,
  de: deTranslations,
};
interface ViewUsersPageProps {
  data: any[];
}

/**
 * ViewUsersPage Component
 *
 * This is the ViewUsersPage component. This is the main page for viewing users.
 * This page displays the users in the system.
 * It shows their first name, last name, email, role, and license key.
 * It also allows the admin to change the role of the user.
 *
 * @returns A ViewUsersPage component
 */
function ViewUsersPage({ data }: ViewUsersPageProps) {
  const { language } = useLanguage();
  //@ts-ignore
  const translations = TRANSLATIONS[language];
  return (
    <MainPageLayout>
      <TableCard>
        <PageTitleSection>
          <PageTitle>{translations.title}</PageTitle>
        </PageTitleSection>
        <ViewUsersTable data={data} />
      </TableCard>
    </MainPageLayout>
  );
}

export default ViewUsersPage;
