// External imports
import React, { useState } from 'react';

// Internal imports
import {
    FlexContainer,
    PlayButton,
    VideoContainer,
    VideoImg,
    VideoTitle,
    VisionBackground,
    VisionParagraph,
    VisionSection,
    VisionTextContainer,
    VisionTitle
} from "../../AboutPage.styles";
import { clinicalMetricsCircleIconSVG } from "../../ClinicalMetricsSvg";
import VideoLightbox from "../../VideoLightbox";

// Assets
// @ts-ignore
import videoPlaceholder from "../../placeholder_img.png";

// Types
import { VisionProps } from './translations/types';

// Language Context
import { useLanguage } from '../../../../core/translations/LanguageContext';

// Translations
import { VisionTranslations } from './translations/types';
import { enTranslations } from './translations/en';
import { deTranslations } from './translations/de';
import { SupportedLanguage } from '../../../../../core/translations/supportedLanguages';

const TRANSLATIONS: Record<SupportedLanguage, VisionTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

/**
 * Vision component
 * Displays the company's vision with text and video content
 */
export const Vision: React.FC<VisionProps> = ({ visionRef }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    const handleVideoClick = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    return (
        <VisionSection ref={visionRef}>
            <VisionBackground />
            {clinicalMetricsCircleIconSVG}
            <FlexContainer>
                <VisionTextContainer>
                    <VisionTitle>{translations.title}</VisionTitle>
                    <VisionParagraph>
                        {translations.paragraphs.challenge}
                    </VisionParagraph>
                    <VisionParagraph>
                        {translations.paragraphs.belief}
                    </VisionParagraph>
                    <VisionParagraph>
                        {translations.paragraphs.solution}
                    </VisionParagraph>
                </VisionTextContainer>

                <VideoContainer
                    onClick={handleVideoClick}
                    role="button"
                    tabIndex={0}
                    aria-label={translations.video.title}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                            handleVideoClick();
                        }
                    }}
                >
                    <div>
                        <VideoImg
                            src={videoPlaceholder}
                            alt={translations.video.alt}
                        />
                        <PlayButton aria-hidden="true" />
                    </div>
                    <VideoTitle>{translations.video.title}</VideoTitle>
                </VideoContainer>

                {modalOpen && (
                    <VideoLightbox
                        videoUrl="https://www.youtube.com/watch?v=3UEOptwfkgg"
                        onRequestClose={handleCloseModal}
                    />
                )}
            </FlexContainer>
        </VisionSection>
    );
};

export default Vision;