import styled from "@emotion/styled";

export const ViewUsersTableContainer = styled.div`
  width: 100%;
  max-height: 72vh;
  font-family: "Helvetica", Arial, sans-serif;
  padding: 0px 1rem;
  overflow-y: auto;

  th,
  td {
    text-align: center;
  }
`;

export const ActionsCard = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  align-items: center;
  justify-content: start;
`;

export const ActionButtons = styled.button<{
  backgroundColorHex?: string;
}>`
  width: 100%;
  text-transform: capitalize;
  padding: 0.55rem 1.125rem;
  border-radius: 0.375rem;
  font-size: 0.9em;
  font-weight: 600;
  color: rgb(255, 255, 255);
  background-color: ${(props) => props.backgroundColorHex ?? "rgb(22 59 93)"};
  text-decoration: none;
  font-family: Helvetica, Arial, sans-serif;
  border: navajowhite;
  cursor: pointer;
`;

export const ActionButtonsWrapper = styled.div`
    min-width:165px;
`;
