// src/pages/not-found/translations/de.ts
import { NotFoundTranslations } from './types';

export const deTranslations: NotFoundTranslations = {
    title: {
        prefix: "4",
        middle: "0",
        suffix: "4"
    },
    messages: {
        pageNotExist: "Diese Seite existiert leider nicht.",
        goBack: "Kehren Sie zur Startseite zurück und erkunden Sie weiter."
    },
    button: {
        text: "Zur Startseite"
    }
};