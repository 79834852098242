import React from "react";
import { ModalContainer, ModalContent } from "./Modal.styles";

/**
 * Props for the Modal component
 */
interface ModalProps {
    children: React.ReactNode;
    width?: string;
    onRequestClose: () => void;
}

/**
 * This is a generic modal component that can be used to display any content in a modal.
 * The functionality should be that when you click the button or outside the modal, the
 * modal should close.
 *
 * @param children - The content to display in the modal (for example, disclaimer text)
 * @returns A modal component
 */
export const Modal: React.FC<ModalProps> = ({
    children,
    width,
    onRequestClose,
}) => {
    return (
        <ModalContainer onClick={onRequestClose}>
            <ModalContent width={width} onClick={(e) => e.stopPropagation()}>
                {children}
            </ModalContent>
        </ModalContainer>
    );
};
