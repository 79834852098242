import { FrontPageTranslations } from "./types";

export const enTranslations: FrontPageTranslations = {
    simulationTraining: "Simulation Training for",
    medicalProfessionals: "Medical Professionals",
    description: "Experience the intensity of the Emergency Room with unlimited virtual patients. Simulate and elevate your medical training with real-time feedback for both students and teachers.",
    imageAlt: "Medical Simulation",
    buttons: {
        signIn: "Sign In",
        bookDemo: "Book a demo",
        manageClasses: "Manage Classes",
        joinClass: "Join a Class"
    }
};