// External imports
import React, { useState, useEffect } from "react";

// Internal imports
import {
  ActionsCard,
  ActionButtons,
  ViewUsersTableContainer,
  ActionButtonsWrapper,
} from "./ViewUsersTable.styles";
import Table from "../../../../components/table/Table";

// Language Context
import { useLanguage } from "../../../../core/translations/LanguageContext";

// Translations
import { UsersPageTranslations } from "../../translations/types";
import { enTranslations } from "../../translations/en";
import { deTranslations } from "../../translations/de";
import { SupportedLanguage } from "../../../../core/translations/supportedLanguages";
import ChangeRoleModal from "../../../../components/modal/change-role-modal/ChangeRoleModal";
import ResetPasswordModal from "../../../../components/modal/reset-password/ResetPassword";
import AddRemoveLicenseKeyModal from "../../../../components/modal/add-remove-license-key/AddRemoveLicenseKey";
import CopyLicenceKey from "../CopyLicenceKey/CopyLicenceKey";

const TRANSLATIONS: Record<SupportedLanguage, UsersPageTranslations> = {
  en: enTranslations,
  de: deTranslations,
};


// Interface for the user details
interface iUser {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  licenseKey: string;
}

const testUsers = [
  {
    firstName: "John",
    lastName: "Smith",
    email: "john.smith@hospital.org",
    role: "Doctor",
    licenseKey: "DOC-2024-001-JS",
  },
  {
    firstName: "Sarah",
    lastName: "Johnson",
    email: "s.johnson@hospital.org",
    role: "Nurse",
    licenseKey: "",
  },
  {
    firstName: "Michael",
    lastName: "Brown",
    email: "m.brown@hospital.org",
    role: "Administrator",
    licenseKey: "ADM-2024-003-MB",
  },
  {
    firstName: "Emma",
    lastName: "Wilson",
    email: "e.wilson@hospital.org",
    role: "Doctor",
    licenseKey: "DOC-2024-004-EW",
  },
  {
    firstName: "Robert",
    lastName: "Davis",
    email: "",
    role: "Nurse",
    licenseKey: "NUR-2024-005-RD",
  },
  {
    firstName: "Lisa",
    lastName: "Anderson",
    email: "l.anderson@hospital.org",
    role: "Doctor",
    licenseKey: "DOC-2024-006-LA",
  },
];

/**
 * ViewUsersTableProp.
 *
 * This is the props interface for the ViewUsersTable component.
 */
export interface ViewUsersTableProps {
  data: iUser[];
}

/**
 * ViewUsersTable Component
 *
 * This component is used to display the discharge patient details table, showing the patients
 * who has been discharged from the department, their diagnosis, and their final "score".
 *
 * @param data - The patient data to display in the table.
 */
export const ViewUsersTable: React.FC<ViewUsersTableProps> = ({ data }) => {
  const { language } = useLanguage();
  //@ts-ignore
  const translations = TRANSLATIONS[language];

  // states to handle modals

  const [showChangeRoleModal, setShowChangeRoleModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showAddRemoveLicenseModal, setShowAddRemoveLicenseModal] =
    useState(false);
  const [selectedLicenseKeyRow, setSelectedLicenseKeyRow] = useState(null);

  // The columns for the discharge patient details table. Note these are different to
  // the columns for the departmental details table.
  const columns = [
    {
      key: "firstName",
      accessor: "firstName",
      label: translations.columns.firstName,
    },
    {
      key: "lastName",
      accessor: "lastName",
      label: translations.columns.lastName,
    },
    {
      key: "email",
      accessor: "email",
      label: translations.columns.email,
    },
    {
      key: "role",
      accessor: "role",
      label: translations.columns.role,
    },
    {
      key: "licenseKey",
      accessor: "licenseKey",
      label: translations.columns.licenseKey,
      sort: true,
      render: (row: any) => {
        return row.licenseKey != "" ? (
          <CopyLicenceKey row={row} />
        ) : (
          <></>
        );
      },
    },
    {
      key: "actions",
      accessor: "actions",
      label: translations.columns.actions,
      render: (row: any) => {
        return (
          <ActionsCard>
            <ActionButtonsWrapper>
              <ActionButtons
                backgroundColorHex="#278230"
                onClick={() => setShowChangeRoleModal(true)}
              >
                {translations.buttons.changeRole}
              </ActionButtons>
            </ActionButtonsWrapper>
            <ActionButtonsWrapper>
              {row.email != "" && (
                <ActionButtons onClick={() => setShowResetPasswordModal(true)}>
                  {translations.buttons.resetPassword}
                </ActionButtons>
              )}
            </ActionButtonsWrapper>

            <ActionButtonsWrapper>
              <ActionButtons
                backgroundColorHex="#cc0000"
                onClick={() => {
                  setSelectedLicenseKeyRow(row);
                  setShowAddRemoveLicenseModal(true);
                }}
              >
                {row.licenseKey == ""
                  ? translations.buttons.addNewLicenseKey
                  : translations.buttons.removeLicenseKey}
              </ActionButtons>
            </ActionButtonsWrapper>
          </ActionsCard>
        );
      },
    },
  ];

  return (
    <ViewUsersTableContainer>
      <Table
        data={testUsers}
        columns={columns as any}
        enableReviewModal={true}
        noRowsText={translations.noRecordText}
      />
      {showChangeRoleModal && (
        <ChangeRoleModal onRequestClose={() => setShowChangeRoleModal(false)} />
      )}
      {showResetPasswordModal && (
        <ResetPasswordModal
          onRequestClose={() => setShowResetPasswordModal(false)}
        />
      )}
      {showAddRemoveLicenseModal && selectedLicenseKeyRow && (
        <AddRemoveLicenseKeyModal
          onRequestClose={() => setShowAddRemoveLicenseModal(false)}
          //@ts-ignore
          selectedLicenseKeyRow={selectedLicenseKeyRow}
        />
      )}
    </ViewUsersTableContainer>
  );
};

export default ViewUsersTable;
