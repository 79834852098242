import styled from "@emotion/styled";

export const PageContainer = styled.div`
  padding: 3em 2rem 1em;
  overflow-x: hidden;
`;
export const BackgroundCard = styled.div`
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  background-image: url(./waiting_room-3SRJTU34.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: calc(-140px + 100vh);
  overflow: hidden;
`;

export const ButtonsCard = styled.div`
  font-family: Helvetica, Arial, sans-serif;
  width: 400px;
  margin: 20px auto 0px;
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid rgb(51, 51, 51);
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  gap: 10px;
`;

export const Button = styled.a`
  font-size: 0.938rem;
  white-space: nowrap;
  font-family: Helvetica, Arial, sans-serif;
  padding: 0.6em 1.3em;
  border-radius: 0.8em;
  cursor: pointer;
  display: inline-flex;
  appearance: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  gap: 0.5em;
  font-weight: 500;
  border: none;
  background-color: rgb(22, 59, 93);
  color: white;
  width: 200px;
  text-decoration: none;
`;
