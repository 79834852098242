import styled from "@emotion/styled";

export const CookiePolicyModalContainer = styled.div`
    width: 61em;
    gap: 1rem;
    color: #000000;
    position: relative;
    padding: 2rem 1.5rem;
    border-radius: 0.75rem;
    background-color: #ffffff;
    margin-bottom:15%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    @media (min-width: 768px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media (max-width: 1024px) {
        width:100%
    }
    font-family: "Helvetica", Arial, sans-serif;
`;

export const CookiePolicyModalContentContainer = styled.div`
    overflow-y: auto;
    max-height: 35em;
    @media (max-width: 1023px) {
       padding-bottom:10%;
    }
`;

export const CookiePolicyModalTitle = styled.h1`
    font-size: 22px;
    margin-top: 5px;
    margin-right:20px;
`;

export const CookiePolicyModalParagraph = styled.p`
    font-size: 15px;
    font-weight: 400;
    margin: 1rem 0px;
    line-height: 1.5;
    margin-right:20px;
    overflow-wrap: anywhere;
`;

export const CookiePolicyModalBoldText = styled.span`
    font-weight: 700;
`;

export const CookiePolicyModalTable = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #dee2e6;
    width: 100%;
    margin-bottom: 1em;
    @media (max-width: 1023px) {
        overflow-x:auto;
        display:block;
    }
`;
export const CookiePolicyModalTableRow = styled.tr`
    
`;
export const CookiePolicyModalTableHead = styled.th`
    padding: 0.75em;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
`;
export const CookiePolicyModalTableData = styled.td`
    padding: 0.75em;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
`;

export const CookiePolicyModalTableBox = styled.div`{
    margin-right:20px;
    @media (max-width: 1023px) {
            width: 95%;
            overflow: hidden;
    }
`;

export const CookiePolicyModalBtns = styled.div`
    display:flex;
    gap:10px;
`;

export const PrintableArea = styled.div`
    padding: 15px;
`;