import styled from "@emotion/styled";

export const NavBar = styled.nav`
  margin: auto;
  box-sizing: border-box;
  height: fit-content;
  gap: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background-color: sky-100;
  padding-top: 3px;
  font-family: "Helvetica Neue", Arial, sans-serif;
  padding: 0.8rem 2rem;
`;

export const AuthButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
`;

export const LinksContainer = styled.div`
  margin-left: 50px;
  display: flex;
  gap: 1rem;
`;

export const FlexGrow = styled.div`
  flex-grow: 1;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
`;

export const NavigationLink = styled.a<{ current?: boolean }>`
  padding: 0.55rem 1.125rem;
  border-radius: 0.375rem;
  font-size: 0.9em;
  font-weight: ${(props) => (props.current ? "600" : "500")};
  color: ${(props) => (props.current ? "#fff" : "#195084")};
  background-color: ${(props) => (props.current ? "#0077b6" : "transparent")};
  text-decoration: none;
`;

export const ProfileMenuContainer = styled.div`
    position: relative;
    display: inline-block;
`;

export const ProfileButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }

    svg {
        width: 24px;
        height: 24px;
        color: #173b5e;
    }
`;

export const ProfileMenu = styled.div`
    position: absolute;
    right: 0;
    top: 100%;
    margin-top: 8px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    min-width: 200px;
    z-index: 1000;
`;

export const ProfileMenuItem = styled.a`
    display: block;
    padding: 12px 16px;
    color: #173b5e;
    text-decoration: none;
    transition: background-color 0.2s;

    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }

    &:first-of-type {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    &:last-of-type {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
`;

export const Anchor = styled.a`

`;