import React from "react";
import { Link } from "react-router-dom";
import { StyledAnchor, StyledButton } from "./WebsiteButton.styles";

export type WebsiteButtonProps<T extends keyof JSX.IntrinsicElements> = {
    href?: string;
    disabled?: boolean;
    children: React.ReactNode;
    className?: string;
    icon?: React.ReactNode;
    iconPosition?: "left" | "right";
    outline?: boolean;
    tag?: T;
    target?: string;
    type?: "button" | "submit" | "reset";
    secondary?: boolean;
    gameNavBar?: boolean;
} & React.HTMLAttributes<JSX.IntrinsicElements[T]>;

export default function WebsiteButton<T extends keyof JSX.IntrinsicElements>({
                                                                                 href,
                                                                                 tag,
                                                                                 children,
                                                                                 className,
                                                                                 icon,
                                                                                 iconPosition = "left",
                                                                                 outline = false,
                                                                                 secondary = false,
                                                                                 ...rest
                                                                             }: WebsiteButtonProps<T>) {
    const isInternalLink = href && href.startsWith("/");

    const Tag =
        tag === "a"
            ? StyledAnchor
            : tag === "button"
                ? (StyledButton as any)
                : tag ?? StyledButton;

    const buttonContent = (
        <Tag
            {...rest}
            className={className}
            outline={outline}
            secondary={secondary}
        >
            {icon && iconPosition === "left" && icon}
            {children}
            {icon && iconPosition === "right" && icon}
        </Tag>
    );

    // Use React Router's Link component for internal navigation
    if (isInternalLink) {
        return <Link to={href}>{buttonContent}</Link>;
    }

    // Fallback to regular anchor tag for external links
    return (
        <a href={href} target={rest.target}>
            {buttonContent}
        </a>
    );
}
