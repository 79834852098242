import { PillStyle } from "./Pill.styles"

export interface PillProps {
    children: string,
    colorHex?: string
    backgroundColorHex?: string
}

export default function Pill(props: PillProps) {
    const { children, colorHex, backgroundColorHex } = props
    return (
        <PillStyle colorHex={colorHex} backgroundColorHex={backgroundColorHex}>
            {children}
        </PillStyle>
    )
}