import { ProductOverviewTranslations } from './types';

export const enTranslations: ProductOverviewTranslations = {
    title: {
        companyName: "is a product of Yellow Bird Consulting"
    },
    description: "Caroline Morton (GP and epidemiologist turned software engineer), Maxwell Flitton (emergency room nurse turned software engineer by way of physics) and Toby Raupach (Consultant Cardiologist and Head of Medical Education) developed Clinical Metrics as a response to inadequate medical simulation training in their experience.",
    buttons: {
        readMore: "Read more",
        website: "yellowbirdconsulting.co.uk"
    }
};