import styled from "@emotion/styled";

export const TableStyle = styled.table`
    overflow: hidden;
    border-radius: 0.375rem;
    border-top-width: 1px;
    border-color: #d1d5db;
    min-width: 100%;
    background-color: #9ca3af;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    border-collapse: collapse;
    border-spacing: 0;
`;

export const THead = styled.thead`
    background-color: rgb(224, 242, 254);
`;

export const Th = styled.th`
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
    text-align: left;
    color: #111827;
    text-align: center;
    font-family: Helvetica, Arial, sans-serif;
    padding: 1rem;
    background: rgb(247, 250, 252);
    border-bottom: 2px solid rgb(226, 232, 240);
    div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        cursor: pointer;
    }
`;

export const TBody = styled.tbody`
    border-top-width: 1px;
    border-color: #e5e7eb;
    background-color: #ffffff;
`;

export const FooterColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

export const FooterColumnContent = styled.div`
    font-size: 1rem;
    line-height: 2.25rem;
    color: #ffffff;
`;
