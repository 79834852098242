import { Label, Input, TextArea } from "./ContactPage.styles";
import { LabelInputPair } from "./ContactPage.styles";

export const LabelInput = ({
  label,
  id,
  type,
  name,
  autoComplete,
  ariaDescribedby,
  defaultValue,
  rows,
}: {
  label: string;
  id: string;
  type: string;
  name: string;
  autoComplete?: string;
  ariaDescribedby?: string;
  defaultValue?: string;
  rows?: number;
}) => {
  return (
    <LabelInputPair>
      <Label htmlFor={id}>{label}</Label>
      {type === "textarea" ? (
        <TextArea
          id={id}
          name={name}
          aria-describedby={ariaDescribedby}
          rows={rows}
          defaultValue={defaultValue}
        />
      ) : (
        <Input
          id={id}
          type={type}
          name={name}
          autoComplete={autoComplete}
          defaultValue={defaultValue}
        />
      )}
    </LabelInputPair>
  );
};
