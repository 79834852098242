import React from "react";
import { createPortal } from "react-dom";
import { ModalDiv } from "./GlobalSmallPopup.styles";
import { OutsideClickHandler } from "./OutsideClickHandler";

const GlobalSmallPopup = ({
  children,
  onRequestClose,
}: {
  children: React.ReactNode;
  onRequestClose: () => void;
}) => {
  const modalRoot = document.getElementById("modal-root")!;
  const el = document.createElement("div");

  React.useEffect(() => {
    modalRoot.appendChild(el);
    return () => {
      modalRoot.removeChild(el);
    };
  }, [el]);

  return createPortal(
    <ModalDiv>
      <div className="overlay">
        <OutsideClickHandler
          onOutsideClick={() => {
            onRequestClose();
          }}
        >
          <div className="content">{children}</div>
        </OutsideClickHandler>
      </div>
    </ModalDiv>,
    el
  );
};

export default GlobalSmallPopup;
