import type { ImpressumContentModal } from "./types";

export const enTranslations: ImpressumContentModal = {
    title: "Disclaimer",
    popupWidth: "100%",
    disclaimerParagraphsArray: [
        {
            section: [
                {
                    normalText:
                        "All information made available by and through the ",
                    boldText: "Clinical Metrics ",
                },
                {
                    normalText:
                        "Website is maintained and updated in good faith for general guidance and educational purposes only and does not guarantee any specific results with regards to assisting or improving any particular learning, training, or educational outcomes. All simulated patient data that is made available in the virtual emergency department is computer generated and completely fictitious, and any similarity of the same with real life patient or patient data is purely coincidental.",
                },
            ],
        },
        {
            section: [
                {
                    normalText: "",
                    boldText: "Clinical Metrics "
                },
                {
                    normalText: "(including its directors, shareholders, employees and agents) is ",
                    boldText: "NOT "
                },
                {
                    normalText: "registered as a licensed medical practitioner with any regulatory authority in any jurisdiction and ",
                    boldText: "DOES NOT "
                },
                {
                    normalText: "provide medical advice, treatment or diagnosis under any circumstances whatsoever. You are solely responsible for determining whether the Website contents are appropriate for you or your specific purpose, based on your personal objectives and circumstances.",
                    boldText: ""
                }
            ]
        },
        {
            section: [
                {
                    normalText: "",
                    boldText: "Clinical Metrics "
                },
                {
                    normalText: "makes no representation as to the completeness or accuracy of any information in the Website and nothing contained therein shall be considered as a referral, endorsement, recommendation, verification, warranty or guarantee with respect to the ",
                    boldText: "validity, accuracy, availability, completeness, safety, legality, quality, "
                },
                {
                    normalText: "or ",
                    boldText: "applicability "
                },
                {
                    normalText: "of any information contained in the Website.",
                    boldText: ""
                }
            ]
        },
        {
            section: [
                {
                    normalText: "In case the Website contains any external weblinks, ",
                    boldText: "Clinical Metrics "
                },
                {
                    normalText: "attempts but does not guarantee that such links will be investigated, monitored, or checked for accuracy, adequacy validity, reliability, availability or completeness at all times. You should neither act, nor refrain from acting, solely on the basis of any information available on the Website and/or any of its affiliated external weblinks.",
                    boldText: ""
                }
            ]
        },
        {
            section: [
                {
                    normalText: "",
                    boldText: "Clinical Metrics "
                },
                {
                    normalText: "cannot and will not have any liability to you for any loss or damage of any kind incurred as a result of the use (or inability to use) of the Website or reliance on any content and information provided on the Website. ",
                    boldText: "YOUR USE OF THE WEBSITE AND YOUR RELIANCE ON ANY CONTENT THEREIN IS SOLELY AND COMPLETELY AT YOUR OWN RISK."
                }
            ]
        },
        {
            section: [
                {
                    normalText: "By continuing to use and access the ",
                    boldText: "Clinical Metrics "
                },
                {
                    normalText: " Website you hereby acknowledge and agree with all of the above. For further details about the Website and its usage, we encourage you to take a look at our ",
                    boldText: "Terms & Conditions, Privacy Policy and Cookies Policy "
                },
                {
                    normalText: "as well.",
                    boldText: ""
                }
            ]
        }
    ],
};
