// External Imports
import React, { useRef } from "react";

// Internal Imports
import { Modal } from "../Modal";
import {
    PrivacyPolicyModalSubtitle,
    PrivacyPolicyModalContainer,
    PrivacyPolicyModalContentContainer,
    PrivacyPolicyModalParagraph,
    PrivacyPolicyModalTitle,
    PrivacyPolicyModalTable,
    PrivacyPolicyModalTableRow,
    PrivacyPolicyModalTableHead,
    PrivacyPolicyModalTableData,
    PrivacyPolicyModalTableBox,
    PrivacyPolicyModalUnorderList,
    PrivacyPolicyModalList,
    PrivacyPolicyModalLink,
    PrivacyPolicyModalBtns,
    PrintableArea
} from "./PrivacyPolicyModal.styles";
import WebsiteButton from "../../button/WebsiteButton";
import PrintButton from "../../button/print/PrintButton";

/**
 * Props for the PrivacyPolicyModal component
 */
interface PrivacyPolicyModalProps {
    onRequestClose: () => void;
}


/**
 * A modal that displays a privacy policy. This contains a privacy policy for the game, with 
 * a single button to close the modal.
 * 
 * @param onClose - A function to call when the modal is closed
 */
export const PrivacyPolicyModal: React.FC<PrivacyPolicyModalProps> = ({ onRequestClose }) => {

    /**
     * Include useRef at the top of the page and create const from it to include on page section.
     * After adding this to printable section pass the same in PrintButton component 
     * Then print button will print the section provided to it.
     */

    const componentRef = useRef();
    // Logic to close the modal
    const closeModal = () => {
        onRequestClose();
    }
    const tableDataArray = [{
        purpose: "Providing our services",
        description: "We will need your personal information to administer our services effectively and carry out our obligations arising from any contracts entered into between you and us. This may include passing your data to third parties such as our business partners, contractors, agents or advisors (e.g. legal, financial, business or other advisors).",
        legal_basis: "Contract performance, Legitimate interests (to enable us to perform our obligations and provide our services to you)"
    },
    {
        purpose: "Improving our services",
        description: "We may collect your personal information for improving our services for purposes of internal training, quality control, research and development. This may include monitoring or recording your live chat with us (as available through the chatbot) and passing your personal information to our business partners and/or service providers. We may also aggregate and/or anonymise your personal information for such purposes.",
        legal_basis: "Legitimate interests (to allow us to improve our services)"
    },
    {
        purpose: "Obtaining feedback",
        description: "We may occasionally contact you to request feedback on our products and services, as a part of our continuous research and development practices.",
        legal_basis: "Legitimate interests (to enable us to gather feedback and improve products and services)"
    },
    {
        purpose: "Ensuring relevance",
        description: "We may use your personal information to analyse how you use our Website, and to ensure that the products and services that we offer are tailored to your needs and interests. We may also aggregate and/or anonymise your personal information for such purposes.",
        legal_basis: "Legitimate interests (to allow us to provide you with the content and services on the Website)"
    },
    {
        purpose: "Marketing",
        description: "We may contact you to provide the latest updates and offers on our products and services, where you have opted-in for such communication. Where required by law, we will ask for your consent at the time we collect your data to conduct any of these types of marketing. We will provide an option to unsubscribe or opt-out of further communication on any electronic marketing communication sent to you or you may opt out by contacting us.",
        legal_basis: "Consent, Legitimate interest (to keep you updated with news in relation to our products and services)"
    },
    {
        purpose: "Informing you of changes",
        description: "We may use your personal information to notify you about changes to our services and products.",
        legal_basis: "Legitimate interests (to notify you about changes to our service); Contract performance"
    },
    {
        purpose: "Reorganising our business",
        description: "In the event that we are (i) subject to negotiations for the sale of our business or part thereof to a third party, (ii) is sold to a third party or (iii) undergo a re-organisation, we may need to transfer some or all of your personal information to the relevant third party (or its advisors) as part of any due diligence process for the purpose of analysing any proposed sale or re-organisation. We may also need to transfer your personal information to that re-organised entity or third party after the sale or reorganisation for them to use for the same purposes as set out in this Privacy Policy.",
        legal_basis: "Legitimate interests (in order to allow us to change our business)"
    },
    {
        purpose: "Ensuring legal or regulatory compliance",
        description: "We may process your personal information to comply with our regulatory requirements or dialogue with its regulators as applicable which may include disclosing your personal information to third parties, the court service and/or regulators or law enforcement agencies in connection with enquiries, proceedings or investigations by such parties anywhere in the world or where compelled to do so. Where permitted, we will direct any such request to you or notify you before responding unless to do so would prejudice the prevention or detection of a crime.",
        legal_basis: "Legal obligations, Legal claims, Legitimate interests (to cooperate with law enforcement and regulatory authorities)"
    }

    ]

    const additionalRights = [
        "The right to be informed - You have the right to be informed about how and why your personal information is being collected or processed by us.",
        "The right to access – You have the right to request a copy of your personal information currently being collected and/or stored by us.",
        "The right to rectification – You have the right to request that we correct any information you believe is inaccurate or incomplete.",
        "The right to erasure – You have the right to request that we erase your personal information, under certain conditions.",
        "The right to restrict processing – You have the right to request that we restrict the processing of your personal information, under certain conditions.",
        "The right to object to processing – You have the right to object to our processing of your personal information, under certain conditions.",
        "The right to data portability – You have the right to request that we transfer the data that we have collected to another organisation, or directly to you, under certain conditions."
    ]

    return (
        <Modal onRequestClose={closeModal}>
            <PrivacyPolicyModalContainer>
                <PrivacyPolicyModalContentContainer>
                    {/* @ts-ignore */}
                    <PrintableArea ref={componentRef}>
                        <PrivacyPolicyModalTitle>PRIVACY POLICY</PrivacyPolicyModalTitle>
                        <PrivacyPolicyModalParagraph>
                            Clinical Metrics is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information when you use the Clinical Metrics Website. We will only use the information that we collect about you in accordance with the applicable privacy laws including but not limited to the EU General Data Protection Regulation 2016/679 (GDPR) and UK Data Protection Act 2018. We reserve the right to update and modify this Privacy Policy at any time and for any reason. You are encouraged to periodically review this Privacy Policy to stay informed of updates.
                        </PrivacyPolicyModalParagraph>
                        <PrivacyPolicyModalParagraph>
                            IF YOU DO NOT AGREE WITH THE PRIVACY POLICY OF THIS PRIVACY POLICY, YOU ARE ADVISED NOT TO CONTINUE USING THE WEBSITE ANY LONGER.
                        </PrivacyPolicyModalParagraph>

                        <PrivacyPolicyModalSubtitle>WHAT INFORMATION WE COLLECT</PrivacyPolicyModalSubtitle>
                        <PrivacyPolicyModalParagraph>
                            While it is perfectly possible to visit and browse the Clinical Metrics Website without disclosing any personal information, if you choose to actively engage with us by creating a user account, you will be asked to provide certain personally identifiable information including your first name, last name, email address, name of university (if available) and academic year. We may also collect some additional information from and about your computer like your IP address, web browser type and version, operating system type and version, browsing history etc. If you are accessing our services through a mobile handheld device, we may request access or permission to certain system information including your mobile device ID number, model, manufacturer, etc. We may also collect further information about you when you request any specific product information, need customer support, or otherwise communicate with us. If you wish to change any of the access permissions, you may do so in your device{`’`}s settings.
                        </PrivacyPolicyModalParagraph>
                        <PrivacyPolicyModalParagraph>
                            No part of our Website is directed towards children below 13 years of age and we do not knowingly collect personal information from anyone under the age of 13. If we learn we have collected or received personal information from a child under 13 years without verification of parental consent, we will promptly delete that information from our database. If you believe we might have any information from or about a child under 13 years, please contact us at <PrivacyPolicyModalLink href="privacy@clinicalmetrics.co.uk">privacy@clinicalmetrics.co.uk.</PrivacyPolicyModalLink>
                        </PrivacyPolicyModalParagraph>
                        <PrivacyPolicyModalSubtitle>HOW WE USE YOUR INFORMATION
                        </PrivacyPolicyModalSubtitle>

                        <PrivacyPolicyModalParagraph>
                            Having accurate information about you permits us to provide you with a smooth, efficient, and customised service. In this section, we set out the purposes for which we use your personal information that we collect. If you are in a country in the European Economic Area (EEA) or in the United Kingdom, in compliance with our obligations under applicable laws (the EU General Data Protection Regulation 2016 and the UK Data Protection Act 2018), we also identify the “legal basis” that we rely on to process such information.
                        </PrivacyPolicyModalParagraph>

                        <PrivacyPolicyModalTableBox>
                            <PrivacyPolicyModalTable>
                                <PrivacyPolicyModalTableRow>
                                    <PrivacyPolicyModalTableHead>Purpose</PrivacyPolicyModalTableHead>
                                    <PrivacyPolicyModalTableHead>Description</PrivacyPolicyModalTableHead>
                                    <PrivacyPolicyModalTableHead>Legal Basis
                                    </PrivacyPolicyModalTableHead>
                                </PrivacyPolicyModalTableRow>
                                {tableDataArray.map((row: any, index: number) => (
                                    <PrivacyPolicyModalTableRow key={index}>
                                        <PrivacyPolicyModalTableData>{row.purpose}</PrivacyPolicyModalTableData>
                                        <PrivacyPolicyModalTableData>{row.description}</PrivacyPolicyModalTableData>
                                        <PrivacyPolicyModalTableData>{row.legal_basis}</PrivacyPolicyModalTableData>
                                    </PrivacyPolicyModalTableRow>
                                ))}

                            </PrivacyPolicyModalTable>
                        </PrivacyPolicyModalTableBox>
                        <PrivacyPolicyModalParagraph>
                            We do not sell any personal information under any circumstances whatsoever. We disclose only that personal information that is necessary for the third party to deliver their specific service and we take all reasonable measures to ensure they keep your information fully secure and not use it for any purpose other than the one under which it was disclosed. All third parties are carefully screened so we can ensure that there are adequate controls in place in accordance with applicable laws.
                        </PrivacyPolicyModalParagraph>
                        <PrivacyPolicyModalSubtitle>
                            USE OF COOKIES
                        </PrivacyPolicyModalSubtitle>
                        <PrivacyPolicyModalParagraph>
                            We may use cookies, web beacons, tracking pixels, Google analytics and/or other tracking technologies on our Website to help customise our services and improve your user experience. However, when you access our Website, your personal information is not collected through the use of any tracking technology. Further, we may at times include or offer third party products or services on our Website. These third-party websites or software may use cookies or similar tracking technology to help manage and optimise your online experience with us. Please note this Privacy Policy will not be applicable in case of any such third-party software and their associated cookies policy. Clinical Metrics bears no responsibility or liability for the content and activities of these third-party links or websites. Please take a look at our Cookies Policy to learn more.
                        </PrivacyPolicyModalParagraph>
                        <PrivacyPolicyModalSubtitle>
                            INFORMATION STORAGE AND SECURITY
                        </PrivacyPolicyModalSubtitle>
                        <PrivacyPolicyModalParagraph>
                            The personal information which we hold will be held securely in accordance with our internal security policy and the appropriate data protection laws as and where applicable. Clinical Metrics has implemented generally accepted standards of technology and operational security in order to protect personal information from loss, misuse, or unauthorised alteration or destruction. Unfortunately, no method of transmission over the Internet, or method of electronic storage, is 100% secure. Whilst we strive to use all reasonable efforts to protect your personal information, we cannot guarantee its absolute security. We will notify you promptly in the event of any breach of your personal information which might expose you to serious risk. We will never collect sensitive information about you without your explicit and informed consent.
                        </PrivacyPolicyModalParagraph>
                        <PrivacyPolicyModalSubtitle>
                            INFORMATION RETENTION AND TRANSFER
                        </PrivacyPolicyModalSubtitle>
                        <PrivacyPolicyModalParagraph>
                            Our retention periods for personal information are based on business needs and legal requirements. We will keep your information for as long as is necessary for the processing purpose(s) for which they were collected and any other permitted linked purpose. We may also further retain information in an anonymous or aggregated form where that information would not identify you personally. We will cease to retain your personal information, or remove the means by which the information can be associated with you, as soon as it is reasonable to assume that such retention no longer serves the purpose for which it was collected, and is no longer necessary for legal or business purposes. When we no longer have a purpose for retaining your personal information, we will destroy it and take commercially reasonable measures to make sure that the information is irrecoverable and irreproducible. All third parties are carefully screened before transfer of any personal information, so we can ensure that there are adequate controls in place and, where relevant, that such third parties are fully compliant with the applicable laws. In case any of any international transfer of personal information, Clinical Metrics takes all reasonable steps to ensure that your personal information continues to receive a standard of protection that is at least comparable to that provided under the applicable laws including the GDPR.
                        </PrivacyPolicyModalParagraph>
                        <PrivacyPolicyModalSubtitle>
                            ADDITIONAL RIGHTS
                        </PrivacyPolicyModalSubtitle>
                        <PrivacyPolicyModalParagraph>
                            Under the applicable privacy laws including GDPR, you possess a number of rights in relation to your personal information. These rights include:
                        </PrivacyPolicyModalParagraph>
                        <PrivacyPolicyModalUnorderList>
                            {additionalRights.map((liData: string, index: number) => (
                                <PrivacyPolicyModalList key={index}>{liData}</PrivacyPolicyModalList>
                            ))}
                        </PrivacyPolicyModalUnorderList>
                        <PrivacyPolicyModalParagraph>
                            We are committed to upholding these rights at all times. If you wish to exercise any of these rights, please send us a request that (a) provides sufficient detail to allow us to verify that you are the person about whom we have collected personal information, and (b) describes your request in sufficient detail to allow us to understand, evaluate, and respond to it as soon as possible. Please note we cannot respond to requests that do not adequately meet the above criteria.
                        </PrivacyPolicyModalParagraph>
                        <PrivacyPolicyModalParagraph>
                            As per GDPR guidelines, Clinical Metrics has a “Data Protection Officer” (DPO) who is responsible for all matters relating to privacy and data protection and they can be reached at:
                        </PrivacyPolicyModalParagraph>
                        <PrivacyPolicyModalParagraph>
                            Attn: Clinical Metrics Data Protection Officer
                        </PrivacyPolicyModalParagraph>
                        <PrivacyPolicyModalParagraph>
                            Address: Building 423 - Sky View (Ro) Argosy Road, East Midlands Airport, Derby, England, DE74 2SA
                        </PrivacyPolicyModalParagraph>
                        <PrivacyPolicyModalParagraph>
                            Email: <PrivacyPolicyModalLink href="privacy@clinicalmetrics.co.uk">privacy@clinicalmetrics.co.uk.</PrivacyPolicyModalLink>
                        </PrivacyPolicyModalParagraph>
                        <PrivacyPolicyModalParagraph>Tel:</PrivacyPolicyModalParagraph>
                        <PrivacyPolicyModalParagraph>
                            You also have the right to complain to a supervisory authority according to Article 51 of GDPR where you feel that our processing of your personal information has infringed your rights. The supervisory authority in the United Kingdom is the Information Commissioner’s Office (ICO): <PrivacyPolicyModalLink href="https://ico.org.uk">https://ico.org.uk.</PrivacyPolicyModalLink>.
                        </PrivacyPolicyModalParagraph>
                        <PrivacyPolicyModalParagraph>
                            If you have any further questions, comments or requests regarding this Privacy Policy, please contact us at <PrivacyPolicyModalLink href="privacy@clinicalmetrics.co.uk">privacy@clinicalmetrics.co.uk.</PrivacyPolicyModalLink>.
                        </PrivacyPolicyModalParagraph>
                        <PrivacyPolicyModalParagraph>
                            This Privacy Policy was last updated in June 2023.
                        </PrivacyPolicyModalParagraph>
                    </PrintableArea>
                    <PrivacyPolicyModalBtns>
                        <WebsiteButton tag="button" onClick={onRequestClose}>
                            Close
                        </WebsiteButton>
                        {/* @ts-ignore */}
                        <PrintButton printableSection={componentRef} />
                    </PrivacyPolicyModalBtns>
                </PrivacyPolicyModalContentContainer>
            </PrivacyPolicyModalContainer>
        </Modal>
    );
}

export default PrivacyPolicyModal;
