// External imports
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Internal imports
import {
  FormContainer,
  LeftPanel,
  LeftPanelInsideContainer,
  Title,
  Logo,
  FormSection,
  RightPanel,
  LanguageSectionWrapper,
  LanguageHeaderEmptyArea,
} from "./LoginPage.styles";
import { LoginForm, FormData } from "./FormComponent/FormComponent";
import { ModalRenderer } from "../../components/header/ModalRenderer";
import { LanguageMenu } from "../../components/language-menu/LanguageMenu";
// Language Context
import { useLanguage } from "../../core/translations/LanguageContext";

// Translations
import { LoginPageTranslations } from "./translations/types";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { SupportedLanguage } from "../../core/translations/supportedLanguages";

const TRANSLATIONS: Record<SupportedLanguage, LoginPageTranslations> = {
  en: enTranslations,
  de: deTranslations,
};

/**
 * LoginPage component
 * Displays a 404 error page when a route doesn't exist
 * Provides a way to navigate back to the home page
 */
const LoginPage: React.FC = () => {
  const { language, setLanguage } = useLanguage();
  //@ts-ignore
  const translations = TRANSLATIONS[language];

  // useNavigate is a hook from react-router-dom that allows us to navigate to different pages
  const navigate = useNavigate();

  // define the state for the form data (will be passed into the login API call)
  const [formStatus, setFormStatus] = useState<FormData>({
    email: "",
    password: "",
    success: false,
    failure: false,
    error: "",
    loading: false,
  });
  const handleFormSubmit = () => {};
  const handleLanguageChange = (newLanguage: SupportedLanguage) => {
    setLanguage(newLanguage);
  };
  return (
    <FormContainer style={{ backgroundImage: `url(outside.jpg)` }}>
      <LanguageSectionWrapper>
        <LanguageHeaderEmptyArea></LanguageHeaderEmptyArea>
        <LanguageMenu value={language} onChange={handleLanguageChange} />
      </LanguageSectionWrapper>

      <LeftPanel>
        <LeftPanelInsideContainer>
          <Logo
            src={"clinical_metrics.png"}
            alt="description"
            onClick={() => navigate("/")}
          />
          <Title>{translations.title}</Title>
          <FormSection>
            <LoginForm
              onSubmit={handleFormSubmit}
              initialFormData={formStatus}
            />
          </FormSection>
        </LeftPanelInsideContainer>
      </LeftPanel>
      <RightPanel>{/*    */}</RightPanel>
      <ModalRenderer />
    </FormContainer>
  );
};

export default LoginPage;
