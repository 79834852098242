// External imports
import React from "react";

// Internal imports
import MainPageLayout from "../../components/header/MainPageLayout";
import {
    PageContainer,
    Title,
    Paragraph,
    GoHomeButton
} from "./NotFoundPage.styles";

// Language Context
import { useLanguage } from '../../core/translations/LanguageContext';

// Translations
import { NotFoundTranslations } from './translations/types';
import { enTranslations } from './translations/en';
import { deTranslations } from './translations/de';
import { SupportedLanguage } from '../../core/translations/supportedLanguages';


const TRANSLATIONS: Record<SupportedLanguage, NotFoundTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

/**
 * NotFoundPage component
 * Displays a 404 error page when a route doesn't exist
 * Provides a way to navigate back to the home page
 */
const NotFoundPage: React.FC = () => {
    const { language } = useLanguage();
    //@ts-ignore
    const translations = TRANSLATIONS[language];

    return (
        <MainPageLayout>
            <PageContainer>
                <Title>
                    {translations.title.prefix}
                    <span>{translations.title.middle}</span>
                    {translations.title.suffix}
                </Title>
                <Paragraph>{translations.messages.pageNotExist}</Paragraph>
                <Paragraph>{translations.messages.goBack}</Paragraph>
                <GoHomeButton href="/">
                    {translations.button.text}
                </GoHomeButton>
            </PageContainer>
        </MainPageLayout>
    );
};

export default NotFoundPage;