import { Modal } from "../Modal";
import {
  AddRemoveLicenseKeyModalContainer,
  AddRemoveLicenseKeyModalContentContainer,
  AddRemoveLicenseKeyModalTitle,
  AddRemoveLicenseKeyModalContentWrapper,
  Form,
  Button,
  SubTitle,
  Select,
  ButtonsWrapper,
} from "./AddRemoveLicenseKey.styles";

// Language Context
import { useLanguage } from "../../../core/translations/LanguageContext";

// Translations
import { AddRemoveLicenseModalTranslations } from "./translations/types";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { SupportedLanguage } from "../../../core/translations/supportedLanguages";

const TRANSLATIONS: Record<
  SupportedLanguage,
  AddRemoveLicenseModalTranslations
> = {
  en: enTranslations,
  de: deTranslations,
};
interface AddRemoveLicenseKeyModalProps {
  onRequestClose: () => void;
  selectedLicenseKeyRow: any;
}
const AddRemoveLicenseKeyModal = ({
  selectedLicenseKeyRow,
  onRequestClose,
}: AddRemoveLicenseKeyModalProps) => {
  const { language } = useLanguage();
  //@ts-ignore
  const translations = TRANSLATIONS[language];
  return (
    <Modal onRequestClose={onRequestClose}>
      <AddRemoveLicenseKeyModalContainer>
        <AddRemoveLicenseKeyModalContentContainer>
          <AddRemoveLicenseKeyModalContentWrapper>
            {selectedLicenseKeyRow.licenseKey == "" ? (
              <Form>
                <AddRemoveLicenseKeyModalTitle>
                  {translations.addNewTitle}
                </AddRemoveLicenseKeyModalTitle>
                <Select>
                  <option> License One </option>
                  <option> License Two </option>
                  <option> License Three </option>
                  <option> License Four </option>
                </Select>
                <Button backgroundColorHex="#102c46">{translations.add}</Button>
              </Form>
            ) : (
              <Form>
                <AddRemoveLicenseKeyModalTitle>
                  {translations.title}
                </AddRemoveLicenseKeyModalTitle>
                <SubTitle>
                  {translations.removalTitle} {selectedLicenseKeyRow.email}
                </SubTitle>
                <ButtonsWrapper>
                  <Button backgroundColorHex="#102c46">
                    {translations.confirm}
                  </Button>
                  <Button backgroundColorHex="#000" onClick={() => onRequestClose()}>
                    {translations.cancel}
                  </Button>
                </ButtonsWrapper>
              </Form>
            )}
          </AddRemoveLicenseKeyModalContentWrapper>
        </AddRemoveLicenseKeyModalContentContainer>
      </AddRemoveLicenseKeyModalContainer>
    </Modal>
  );
};

export default AddRemoveLicenseKeyModal;
