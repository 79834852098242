import styled from "@emotion/styled";

export const Title = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: #173b5e;

  @media (min-width: 640px) {
    font-size: 2.25rem;
  }
`;
export const Description = styled.p`
  margin-top: 1rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: #173b5e;

  @media (min-width: 640px) {
    margin-top: 0.75rem;
  }
`;
export const Form = styled.form`
  margin-top: 2.25rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const sharedInputStyle = `
  display: block;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #ccc;
  border-radius: 0.375rem;
  padding: .5em 1em;
  font-size: 0.875rem;
  color: #4b5563;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  &:focus {
    outline: 2px solid #3b82f6;
    outline-offset: 2px;
  }
`;

export const Input = styled.input`
  ${sharedInputStyle}
`;

export const TextArea = styled.textarea`
  ${sharedInputStyle}
  resize: none;
`;

export const Label = styled.label`
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #4b5563;
  margin-bottom: 0.5rem;
`;
export const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;
export const Message = styled.span`
  white-space: nowrap;
  color: #3b82f6;
  font-weight: 500;
  font-size: 1.125rem;
`;
export const ImageStyle = styled.img`
  width: 100%;
  height: auto;
  border-radius: 1rem;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  @media (max-width: 1240px) and (min-width: 1024px) {
    width: calc(70% + 4rem);
    margin-right: 0;
    transform: scale(1.5) translateX(20%);
  }
`;
export const ContentContainer = styled.div`
  width: 100%;
  @media (min-width: 1024px) {
    width: 40%;
    text-align: left;
  }
`;
export const ImageContainer = styled.div`
  width: 100%;
  @media (min-width: 1024px) {
    width: 60%;
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 2rem;
  align-items: center;
  padding: 2rem 0;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;
export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const LabelInputPair = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
