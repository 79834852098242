// External imports
import React from "react";
import { HiOutlineArrowDown } from "react-icons/hi2";

// Internal imports
import {
    ButtonContainer,
    Paragraph,
    TextContainer,
    Title
} from "../../AboutPage.styles";
import { clinicalMetricsSVG } from "../../ClinicalMetricsSvg";
import WebsiteButton from "../../../../components/button/WebsiteButton";

// Types
import { ProductOverviewProps } from './translations/types';

// Language Context
import { useLanguage } from '../../../../core/translations/LanguageContext';

// Translations
import { ProductOverviewTranslations } from './translations/types';
import { enTranslations } from './translations/en';
import { deTranslations } from './translations/de';
import { SupportedLanguage } from '../../../../../core/translations/supportedLanguages';

const TRANSLATIONS: Record<SupportedLanguage, ProductOverviewTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

/**
 * ProductOverview component
 * Displays company overview and provides navigation to vision section
 */
export const ProductOverview: React.FC<ProductOverviewProps> = ({ handleReadMore }) => {
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    return (
        <TextContainer>
            <Title>
                {clinicalMetricsSVG}
                <span>{translations.title.companyName}</span>
            </Title>
            <Paragraph>
                {translations.description}
            </Paragraph>
            <ButtonContainer>
                <WebsiteButton
                    onClick={handleReadMore}
                    icon={<HiOutlineArrowDown size="1.3em" />}
                >
                    {translations.buttons.readMore}
                </WebsiteButton>
                <WebsiteButton
                    iconPosition="right"
                    target="_blank"
                    icon={
                        <svg
                            width="12"
                            height="11"
                            viewBox="0 0 12 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.58802 0.779297H10.8333M10.8333 0.779297V9.94186M10.8333 0.779297L1.13672 9.94186"
                                stroke="currentColor"
                                strokeWidth="1.44646"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    }
                    outline
                    href="https://yellowbirdconsulting.co.uk"
                >
                    {translations.buttons.website}
                </WebsiteButton>
            </ButtonContainer>
        </TextContainer>
    );
};

export default ProductOverview;