import type { HeaderTranslations } from './types';

export const deTranslations: HeaderTranslations = {
  header: {
    home: "Startseite",
    about: "Über uns",
    contact: "Kontakt",
    bookDemo: "Demo buchen",
    login: "Anmelden",
    profile: {
      menu: {
        profile: "Profil",
        badges: "Abzeichen"
      }
    }
  }
};