import { NavigationTranslations } from "./types";

export const deTranslations: NavigationTranslations = {
    solutions: {
        research: "Forschung",
        analytics: "Analysen"
    },
    support: {
        pricing: "Preise",
        documentation: "Dokumentation",
        guides: "Anleitungen"
    },
    company: {
        about: "Über uns",
        blog: "Blog",
        press: "Presse",
        partners: "Partner"
    },
    legal: {
        claim: "Impressum",
        privacy: "Datenschutz",
        terms: "AGB"
    },
    social: {
        twitter: "Twitter"
    }
};