// External Imports
import React, { useRef } from "react";

// Internal Imports
import { Modal } from "../Modal";

import {
    CookiePolicyModalContainer,
    CookiePolicyModalContentContainer,
    CookiePolicyModalParagraph,
    CookiePolicyModalTitle,
    CookiePolicyModalTable,
    CookiePolicyModalTableRow,
    CookiePolicyModalTableHead,
    CookiePolicyModalTableData,
    CookiePolicyModalTableBox,
    CookiePolicyModalBtns,
    PrintableArea
} from "./CookiePolicyModal.styles";
import WebsiteButton from "../../button/WebsiteButton";
import PrintButton from "../../button/print/PrintButton";

/**
 * Props for the CookiePolicyModal component
 */
interface CookiePolicyModalProps {
    onRequestClose: () => void;
}

/**
 * A modal that displays a cookie policy. This contains a cookie policy for the game, with 
 * a single button to close the modal.
 * 
 * @param onClose - A function to call when the modal is closed
 */
export const CookiePolicyModal: React.FC<CookiePolicyModalProps> = ({ onRequestClose }) => {
    
/**
 * Include useRef at the top of the page and create const from it to include on page section.
 * After adding this to printable section pass the same in PrintButton component 
 * Then print button will print the section provided to it.
 */

    const componentRef = useRef();
    // Logic to close the modal
    const closeModal = () => {
        console.log("Popup closed");
        localStorage.setItem("cookiesSeen", "true");

        onRequestClose();
    }

    return (
        <Modal onRequestClose={closeModal}>
            <CookiePolicyModalContainer >
                <CookiePolicyModalContentContainer>
                    {/* @ts-ignore */}
                    <PrintableArea ref={componentRef}>
                        <CookiePolicyModalTitle>COOKIES POLICY</CookiePolicyModalTitle>
                        <CookiePolicyModalParagraph>
                            This Cookies Policy explains how Clinical Metrics uses cookies and similar technologies in their Website to collect certain information about you. This document explains what these technologies are and why we use them, as well as your rights to control our use of them. Clinical Metrics reserve the right to update and modify this Cookies Policy at any time and for any reason. You are encouraged to periodically review this document to stay informed of updates (please see the date at the end of this document for the most recent update).
                        </CookiePolicyModalParagraph>
                        <CookiePolicyModalTitle className="page-break">1. WHAT ARE COOKIES</CookiePolicyModalTitle>
                        <CookiePolicyModalParagraph className="page-break">
                            Cookies are text files containing small amounts of information which are downloaded to your computer or mobile device when you visit a website. Cookies are widely used to make websites function more efficiently, help you navigate between the different pages of the website, or remember information about you, such as your language preference or login information.
                        </CookiePolicyModalParagraph>
                        <CookiePolicyModalParagraph>
                            Cookies are broadly of two types, first party cookies and third party cookies. The cookies that are set directly by us are called first party cookies. The cookies that are set from a domain different than the domain of the website you are visiting, or in other words, are served by a third party, on our behalf, are called third party cookies. Third party cookies are typically used for web analytics, performance and marketing activities.
                        </CookiePolicyModalParagraph>
                        <CookiePolicyModalParagraph>
                            Clinical Metrics uses cookies and other tracking technologies for the following purposes: Assisting you in navigation; Assisting in registration to our events, login, and your ability to provide feedback; Analysing your use of our products, services or applications; Assisting with our promotional and marketing efforts (including behavioural advertising)
                        </CookiePolicyModalParagraph>
                        <CookiePolicyModalParagraph>
                            Cookies can be programmed to remain on your computer or mobile device for various periods of time. Session cookies are temporary cookies that exist only while your browser is open and is deleted automatically when you close your browser. Persistent cookies are more permanent in nature and remain on your device even after the browser is closed (for example to remember your user preferences when you return to the site). These cookies do not collect or store any personally identifiable information. You can refuse the use of cookies or you can opt-out of each cookie category (except strictly necessary cookies) by clicking on the “cookie settings” button.
                        </CookiePolicyModalParagraph>
                        <CookiePolicyModalTitle>
                            2. THE TYPES OF COOKIES WE USE
                        </CookiePolicyModalTitle>
                        <CookiePolicyModalParagraph>
                            Below is a list of the main cookies we use on our Website and a brief explanation of their use. A complete list of all the cookies used by Clinical Metrics appears at Appendix 1.
                        </CookiePolicyModalParagraph>
                        <CookiePolicyModalParagraph>
                            Strictly necessary cookies These cookies are essential in order to enable you to move around our Website and use their features. Without these cookies, services you have asked for (such as navigating between pages) cannot be provided.
                        </CookiePolicyModalParagraph>
                        <CookiePolicyModalParagraph>
                            Performance Cookies These cookies collect information about how you use our Website, for instance which pages users go to most often, and if they get error messages from web pages. It is only used to improve how the website works. We use Google Analytics and similar analytical tools which use performance cookies to provide us with information on how the Website is performing and how you use the Website.
                        </CookiePolicyModalParagraph>
                        <CookiePolicyModalParagraph>
                            Functional Cookies We make use of cookies to provide you with certain functionality. For example, to remember choices you make (such as your user name, language or the region you are in), or to recognise the platform from which you access the Website, and to provide enhanced and more personal features. These cookies are not used to track your browsing on other websites.
                        </CookiePolicyModalParagraph>
                        <CookiePolicyModalParagraph>
                            Targeting Cookies These cookies may be set through our Website by our advertising partners to record information about your visit and build a profile of your interests and show you relevant advertisements on other websites. These cookies record the pages you have visited, the links you have followed, the number of times you have seen any particular advertisement, the advertisements you have clicked on etc. If you do not allow these cookies, you will experience less targeted advertising. These cookies do not directly store personal information, but are based on uniquely identifying your browser and internet device.
                        </CookiePolicyModalParagraph>
                        <CookiePolicyModalTitle>
                            3. OTHER TRACKING TECHNOLOGIES
                        </CookiePolicyModalTitle>
                        <CookiePolicyModalParagraph>
                            In addition to the different types of cookies mentioned above, we may use other, similar technologies from time to time, like web beacons (sometimes called "tracking pixels" or "clear gifs"). These are tiny graphics files that contain a unique identifier that enable us to recognise when someone has visited our Website or opened an e-mail including them. This allows us, for example, to monitor the traffic patterns of users from one page within the Website to another, to deliver or communicate with cookies, to understand whether you have come to the Website from an online advertisement displayed on a third-party website, to improve Website performance, and to measure the success of e-mail marketing campaigns. In many instances, these technologies are reliant on cookies to function properly, and so declining cookies will impair their functioning.
                        </CookiePolicyModalParagraph>
                        <CookiePolicyModalTitle>
                            4. HOW TO CONTROL OR DELETE COOKIES
                        </CookiePolicyModalTitle>
                        <CookiePolicyModalParagraph>
                            If you don{`’`}t want to receive cookies, you can modify your browser so that it notifies you when cookies are sent to it or you can refuse cookies altogether. You can also delete cookies that have already been set. However, please note that we may still use certain information collected from cookies prior to your deletion / disabled preference being set. We will stop using the deleted / disabled cookie to collect any further information.
                        </CookiePolicyModalParagraph>
                        <CookiePolicyModalParagraph>
                            If you wish to restrict or block web browser cookies which are set on your device, then you can do this through your browser settings; the Help function within your browser should tell you how. Alternatively, you may wish to visit www.aboutcookies.org or www.allaboutcookies.org (link is external), which contains comprehensive information on how to do this on a wide variety of desktop browsers.
                        </CookiePolicyModalParagraph>
                        <CookiePolicyModalParagraph>
                            This Cookies Policy does not cover third party websites. Please note that this Cookies Policy does not apply to, and Clinical Metrics is not responsible for, the privacy practices of third party websites which may be linked to or accessible through our Website.
                        </CookiePolicyModalParagraph>
                        <CookiePolicyModalTitle>5. CONTACT US</CookiePolicyModalTitle>
                        <CookiePolicyModalParagraph>
                            Please see our Privacy Policy to know more about how to contact us.
                        </CookiePolicyModalParagraph>
                        <CookiePolicyModalTitle>APPENDIX 1 – FULL LIST OF CLINICAL METRICS WEBSITE COOKIES</CookiePolicyModalTitle>
                        <CookiePolicyModalTableBox>
                            <CookiePolicyModalTable>
                                <CookiePolicyModalTableRow>
                                    <CookiePolicyModalTableHead>Cookie Name</CookiePolicyModalTableHead>
                                    <CookiePolicyModalTableHead>Category</CookiePolicyModalTableHead>
                                    <CookiePolicyModalTableHead>Description and Purpose
                                    </CookiePolicyModalTableHead>
                                    <CookiePolicyModalTableHead>Duration</CookiePolicyModalTableHead>
                                </CookiePolicyModalTableRow>
                                <CookiePolicyModalTableRow>
                                    <CookiePolicyModalTableData>applicationState</CookiePolicyModalTableData>
                                    <CookiePolicyModalTableData>Functional cookie</CookiePolicyModalTableData>
                                    <CookiePolicyModalTableData>This cookie is used to enable login in, using the product and session management. It holds information including JWT tokens (userToken, gamesessiontoken, and classToken)</CookiePolicyModalTableData>
                                    <CookiePolicyModalTableData>When log out or shut browser
                                    </CookiePolicyModalTableData>
                                </CookiePolicyModalTableRow>
                            </CookiePolicyModalTable>
                        </CookiePolicyModalTableBox>
                        <CookiePolicyModalParagraph>
                            This Cookies Policy was last updated in June 2023.
                        </CookiePolicyModalParagraph>
                    </PrintableArea>
                    <CookiePolicyModalBtns>
                        <WebsiteButton tag="button" onClick={closeModal}>
                            Close
                        </WebsiteButton>
                        {/* @ts-ignore */}
                        <PrintButton printableSection={componentRef} />
                    </CookiePolicyModalBtns>
                </CookiePolicyModalContentContainer>
            </CookiePolicyModalContainer>
        </Modal>
    );
}

export default CookiePolicyModal;
