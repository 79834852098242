import styled from "@emotion/styled";

export const DisclaimerModalContainer = styled.div`
    width: 100%;
    gap: 1rem;
    color: #000000;
    position: relative;
    padding: 2rem 1.5rem;
    border-radius: 0.75rem;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom:15%;
    @media (min-width: 768px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    font-family: "Helvetica", Arial, sans-serif;
`;

export const DisclaimerModalContentContainer = styled.div`
    overflow-y: auto;
    max-height: 35em;
    @media (max-width: 1023px) {
    padding-bottom:10%;
    }`;

export const DisclaimerModalTitle = styled.h1`
    font-size: 22px;
    margin-top: 5px;
`;

export const DisclaimerModalParagraph = styled.p`
    font-size: 15px;
    font-weight: 400;
    margin: 1rem 0px;
    line-height: 1.5;
    margin-right:20px;
`;

export const DisclaimerModalBoldText = styled.span`
    font-weight: 700;
`;

export const DisclaimerModalBtns = styled.div`
    display:flex;
    gap:10px;
`;

export const PrintableArea = styled.div`
    padding: 15px;
`;