import styled from "@emotion/styled";

export const ProfileDropdownContainer = styled.div`
    font-family: "Helvetica", "Arial", sans-serif;
    position: relative;
    display: inline-flex;
    & > div {
        display: ${({ menuOpen }: { menuOpen: boolean }) =>
            menuOpen ? "block" : "none"};
        position: absolute;
        right: 0;
        top: 100%;
        z-index: 40;
    }
`;

export const ProfileDropdownButton = styled.button`
    background: #7cd3fc;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2em;
    height: 2em;
    font-size: 1.3em;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    appearance: none;
`;

export const ProfileDropdownMenu = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    background: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    border-radius: 0.5em;
    top: 100%;
    right: 0;
    width: 200px;
    z-index:1;
`;

export const ProfileDropdownMenuItem = styled.li`
    padding: 0.7em 1em;
    cursor:pointer;
    &:hover {
        background: #f0f0f0;
    }
    a {
        color: #000;
        text-decoration: none;
    }
`;
