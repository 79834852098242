import { ComponentProps } from "react";
import { Modal } from "../Modal";
import {
  ChangeRoleModalContainer,
  ChangeRoleModalContentContainer,
  ChangeRoleModalTitle,
  ChangeRoleModalContentWrapper,
  Form,
  Select,
  Button,
} from "./ChangeRoleModal.styles";

// Language Context
import { useLanguage } from "../../../core/translations/LanguageContext";

// Translations
import { ChangeRoleModalTranslations } from "./translations/types";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { SupportedLanguage } from "../../../core/translations/supportedLanguages";

const TRANSLATIONS: Record<SupportedLanguage, ChangeRoleModalTranslations> = {
  en: enTranslations,
  de: deTranslations,
};
const ChangeRoleModal = (
  props: Omit<ComponentProps<typeof Modal>, "children">
) => {
  const { language } = useLanguage();
  //@ts-ignore
  const translations = TRANSLATIONS[language];
  return (
    <Modal {...props}>
      <ChangeRoleModalContainer>
        <ChangeRoleModalContentContainer>
          <ChangeRoleModalContentWrapper>
            <Form>
              <ChangeRoleModalTitle>{translations.title}</ChangeRoleModalTitle>
              <Select>
                {translations.options.map((role, index) => (
                  <option value={index} key={index}>
                    {role}
                  </option>
                ))}
              </Select>
              <Button>{translations.submit}</Button>
            </Form>
          </ChangeRoleModalContentWrapper>
        </ChangeRoleModalContentContainer>
      </ChangeRoleModalContainer>
    </Modal>
  );
};

export default ChangeRoleModal;
