import styled from "@emotion/styled";
export const CopyText = styled.p`
  width: 100%px;
  text-align: center;
  padding: 0px;
  margin: 0px;
`;

export const CopyTextSpan = styled.span`
  color: #334155;
`;

export const ContentWrapper = styled.div`
background: rgb(51, 65, 85);
color: rgb(255, 255, 255);
width: min-content;
display: flex;
gap: 10px;
-webkit-box-align: center;
align-items: center;
-webkit-box-pack: center;
justify-content: center;
border-radius: 0.8em;
font-size: 0.9em;
font-weight: 600;
padding: 0px 10px;
svg {
  padding: 10px;
  font-size: 40px;
  cursor: pointer;
}
`;