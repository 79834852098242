// External imports
import React from 'react';
import { FiTwitter } from "react-icons/fi";

// Internal imports
import { FooterSocialIcon } from "./FooterSocialIcon";

// Translations
import { NavigationTranslations } from './translations/types';
import { enTranslations } from './translations/en';
import { deTranslations } from './translations/de';
import { SupportedLanguage } from '../../../../core/translations/supportedLanguages';

/**
 * Interface for navigation link items
 */
interface NavigationLink {
  name: string;
  href: string;
}

/**
 * Interface for social media link items
 */
interface SocialLink extends NavigationLink {
  icon: React.ReactNode;
}

/**
 * Interface for complete footer navigation structure
 */
interface FooterNavigation {
  solutions: NavigationLink[];
  support: NavigationLink[];
  company: NavigationLink[];
  legal: NavigationLink[];
  social: SocialLink[];
}

const TRANSLATIONS: Record<SupportedLanguage, NavigationTranslations> = {
  en: enTranslations,
  de: deTranslations,
};

/**
 * Hook that provides footer navigation data with translations
 * @returns Object containing footer navigation structure
 */
const useFooterNavigation = () => {
  const language = localStorage.getItem('language') as SupportedLanguage || 'en';
  const translations = TRANSLATIONS[language];

  const footerNavigation: FooterNavigation = {
    solutions: [
      { name: translations.solutions.research, href: "/research" },
      { name: translations.solutions.analytics, href: "/analytics" },
    ],
    support: [
      { name: translations.support.pricing, href: "/products" },
      {
        name: translations.support.documentation,
        href: "https://docs.clinicalmetrics.co.uk",
      },
      { name: translations.support.guides, href: "/guides" },
    ],
    company: [
      { name: translations.company.about, href: "/about" },
      { name: translations.company.blog, href: "/blog" },
      { name: translations.company.press, href: "/press" },
      { name: translations.company.partners, href: "/partners" },
    ],
    legal: [
      { name: translations.legal.claim, href: "/?impressum" },
      { name: translations.legal.privacy, href: "/?privacy-policy" },
      { name: translations.legal.terms, href: "/?terms-and-conditions" },
    ],
    social: [
      {
        name: translations.social.twitter,
        href: "https://twitter.com/ClinicalMetrics",
        icon: <FooterSocialIcon Icon={FiTwitter} />,
      },
    ],
  };

  return { footerNavigation };
};

export default useFooterNavigation;