// External Imports
import React, { useRef } from "react";

// Internal Imports
import { Modal } from "../Modal";
import {
    ImpressumModalContainer,
    ImpressumModalContentContainer,
    ImpressumModalParagraph,
    ImpressumModalTitle,
    ImpressumModalSubtitle,
    ImpressumModalLink,
    ImpressumModalBtns,
    PrintableArea,
    ImpressumModalBoldText,
    ImpressumModalNormalText,
} from "./ImpressumModal.styles";
import WebsiteButton from "../../button/WebsiteButton";
import PrintButton from "../../button/print/PrintButton";

// Language Context
import { useLanguage } from "../../../core/translations/LanguageContext";

// Translations
import { ImpressumContentModal } from "./translations/types";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { SupportedLanguage } from "../../../core/translations/supportedLanguages";

const TRANSLATIONS: Record<SupportedLanguage, ImpressumContentModal> = {
    en: enTranslations,
    de: deTranslations,
};

/**
 * Props for the ImpressumModalPreview component
 */
interface ImpressumModalProps {
    onRequestClose: () => void;
}

/**
 * A modal that displays a impressum. This contains a impressum for the game, with
 * a single button to close the modal.
 *
 * @param onClose - A function to call when the modal is closed
 */
export const ImpressumModalPreview: React.FC<ImpressumModalProps> = ({
    onRequestClose,
}) => {
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    /**
     * Include useRef at the top of the page and create const from it to include on page section.
     * After adding this to printable section pass the same in PrintButton component
     * Then print button will print the section provided to it.
     */

    const componentRef = useRef();
    // Logic to close the modal
    const closeModal = () => {
        onRequestClose();
    };

    return (
        <Modal onRequestClose={closeModal}>
            <ImpressumModalContainer width={translations.popupWidth}>
                <ImpressumModalContentContainer>
                    {/* @ts-ignore */}
                    <PrintableArea ref={componentRef}>
                        <ImpressumModalTitle>
                            {translations.title}
                        </ImpressumModalTitle>
                        {translations?.disclaimerParagraphsArray?.map(
                            (paragraph, index) => (
                                <ImpressumModalParagraph key={index}>
                                    {paragraph.section.map(
                                        (sectionContent, i) => (
                                            <>
                                                <ImpressumModalNormalText>
                                                    {sectionContent.normalText}
                                                </ImpressumModalNormalText>
                                                
                                                <ImpressumModalBoldText>
                                                    {sectionContent.boldText} {" "}
                                                </ImpressumModalBoldText>
                                            </>
                                        )
                                    )}
                                </ImpressumModalParagraph>
                            )
                        )}

                        <ImpressumModalSubtitle>
                            {translations.subTitle}
                        </ImpressumModalSubtitle>
                        <ImpressumModalParagraph>
                            {translations.summary}
                        </ImpressumModalParagraph>
                        <ImpressumModalSubtitle>
                            {translations?.address?.title}
                        </ImpressumModalSubtitle>
                        <ImpressumModalParagraph>
                            {translations?.address?.detail}
                        </ImpressumModalParagraph>
                        <ImpressumModalSubtitle>
                            {translations?.companyDetail?.title}
                        </ImpressumModalSubtitle>
                        {translations?.companyDetail?.info?.map(
                            (info, index) => (
                                <ImpressumModalParagraph key={index}>
                                    {info}
                                </ImpressumModalParagraph>
                            )
                        )}

                        <ImpressumModalSubtitle>
                            {translations?.contactDetail?.title}
                        </ImpressumModalSubtitle>
                        <ImpressumModalLink
                            href={`mailto:${translations?.contactDetail?.email}`}
                        >
                            {translations?.contactDetail?.email}
                        </ImpressumModalLink>
                        <ImpressumModalSubtitle>
                            {translations?.disclaimer?.title}
                        </ImpressumModalSubtitle>
                        {translations?.disclaimer?.sections?.map(
                            (section, index) => (
                                <div key={index}>
                                    <ImpressumModalSubtitle>
                                        {section.point}
                                    </ImpressumModalSubtitle>
                                    <ImpressumModalParagraph>
                                        {section.pointDetail}
                                    </ImpressumModalParagraph>
                                </div>
                            )
                        )}
                    </PrintableArea>
                    <ImpressumModalBtns>
                        <WebsiteButton tag="button" onClick={onRequestClose}>
                            Close
                        </WebsiteButton>
                        {/* @ts-ignore */}
                        <PrintButton printableSection={componentRef} />
                    </ImpressumModalBtns>
                </ImpressumModalContentContainer>
            </ImpressumModalContainer>
        </Modal>
    );
};
//@ts-ignore
export default ImpressumModalPreview;
