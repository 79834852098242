import { ContactTranslations } from "./types";

export const deTranslations: ContactTranslations = {
    title: "Kontaktieren Sie uns",
    description: "Wenn Sie eine Frage haben oder Kontakt aufnehmen möchten, senden Sie uns bitte eine Nachricht über das untenstehende Formular",
    form: {
        name: "Name",
        email: "E-Mail",
        helpMessage: "Wie können wir Ihnen helfen?",
        charLimit: "Maximal 500 Zeichen",
        submit: "Abschicken",
        thankYou: "Danke! Wir melden uns so bald wie möglich."
    }
};