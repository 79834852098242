import styled from "@emotion/styled";

export const ModalDiv = styled.div`
  font-family: "Helvetica", Arial, sans-serif;
  &,
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    height: auto;
    width: 30%;
    padding: 20px;
    border-radius: 4px;
    border-radius: 15px;
    @media screen and (max-width:767px) {
      width: 90%
    }
  }
`;
