import { FooterTranslations } from "./types";

export const enTranslations: FooterTranslations = {
    footer: "Footer",
    tagline: "Making the world a better place through training safer doctors",
    copyright: "©️ 2024 Yellow Bird Consulting. All rights reserved.",
    sections: {
        solutions: "Solutions",
        support: "Support",
        company: "Company",
        legal: "Legal"
    }
};