import { UsersPageTranslations } from './types';

export const deTranslations: UsersPageTranslations = {
    title: "Benutzer",
    columns: {
        firstName: "Vorname",
        lastName: "Nachname",
        email: "E-Mail",
        role: "Rolle",
        licenseKey: "Lizenzschlüssel",
        actions: "Aktionen",
    },
    buttons: {
        changeRole: "Rolle ändern",
        resetPassword: "Passwort zurücksetzen",
        removeLicenseKey: "Lizenzschlüssel entfernen",
        addNewLicenseKey: "Lizenzschlüssel hinzufügen"
    },
    noRecordText: "Noch keine Benutzer",
    copyConfirmation: "Kopiert!"
};
