import styled from "@emotion/styled";

export const LogoutModalContainer = styled.div`
    width: 100%;
    gap: 1rem;
    color: #000000;
    position: relative;
    border-radius: 0.75rem;

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
`;

export const LogoutModalContentContainer = styled.div`
    overflow-y: auto;
    max-height: 35em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
`;

export const LogoutModalTitle = styled.h1`
    font-size: 22px;
    margin-top: 5px;
`;

export const LogoutModalSubTitle = styled.h5`
    font-size: 15px;
    text-align: center;
`;

export const LogoutModalContentWrapper = styled.div`
    margin-top: 1rem;
    background: #334155;
    color: #fff;
    width: min-content;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 0.8em;
    svg {
        padding: 10px;
        font-size: 40px;
        cursor: pointer;
    }
`;

export const LogoutModalText = styled.p`
    padding: 15px;
    width: 100%px;
    text-align: center;
`;

export const LogoutModalSpan = styled.span`
    color: #334155;
`;
export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 15px;
    width: 100%;
`;
export const LogoutButton = styled.button`
    background-color: rgb(51 65 85);
    border-radius: 0.8em;
    font-size: 0.938rem;
    font-weight: 500;
    padding:0.6em 1.3em;
`;
export const CancelButton = styled.button`
    background-color: #858e98;
    border-radius: 0.8em;
    font-size: 0.938rem;
    font-weight: 500;
        padding:0.6em 1.3em;

`;
