import type { HeaderTranslations } from './types';

export const enTranslations: HeaderTranslations = {
  header: {
    home: "Home",
    about: "About",
    contact: "Contact",
    bookDemo: "Book a demo",
    login: "Login",
    profile: {
      menu: {
        profile: "Profile",
        badges: "Badges"
      }
    }
  }
};