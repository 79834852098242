// External imports
import React from "react";
import { HiOutlineArrowRight } from "react-icons/hi2";

// Internal imports
import WebsiteButton from "../../components/button/WebsiteButton";
import MainPageLayout from "../../components/header/MainPageLayout";
//@ts-ignore
import outside from "./outside.jpg";
import {
  PageContainer,
  BackgroundCard,
  ButtonsCard,
  Button
} from "./FrontPage.styles";
import { getJwt } from "../../core/LocalStorage/AuthSession/core";

// Dependency injection
import { GetRoleType } from "../../core/LocalStorage/AuthSession/core";

// Language Context
import { useLanguage } from "../../core/translations/LanguageContext";

// Translations
import { FrontPageTranslations } from './translations/types';
import { enTranslations } from './translations/en';
import { deTranslations } from './translations/de';
import { SupportedLanguage } from '../../core/translations/supportedLanguages';

/**
 * Props for the FrontPage component
 * @property getRoleApiCall - Function to fetch the user's role
 */
export interface FrontPageProps {
  //getRoleApiCall: GetRoleType;
}

const TRANSLATIONS: Record<SupportedLanguage, FrontPageTranslations> = {
  en: enTranslations,
  de: deTranslations,
};

/**
 * Front page component of the website
 * Displays main content, hero section, and conditional navigation based on user auth state
 */
export const FrontPage: React.FC<FrontPageProps> = () => {
  // Use the language context instead of localStorage directly
  const { language } = useLanguage();
  // @ts-ignore
  const translations = TRANSLATIONS[language];

  // Check if the user is logged in
  const loggedIn = getJwt();

  // Get the user's role if logged in
  const role = "Admin";

  return (
      <MainPageLayout>
        <PageContainer>
          <BackgroundCard>
            <ButtonsCard>
              <Button href="/institutions">View Institutions</Button>
              <Button href="/users">View Users</Button>
            </ButtonsCard>
          </BackgroundCard>
        </PageContainer>
      </MainPageLayout>
  );
};