import { AddRemoveLicenseModalTranslations } from './types';

export const deTranslations: AddRemoveLicenseModalTranslations = {
    title: "Lizenzschlüssel entfernen",
    removalTitle: "Lizenzschlüssel für entfernen ",
    addNewTitle: "Lizenzschlüssel hinzufügen",
    confirm: "Bestätigen",
    cancel: "Abbrechen",
    add: "Absenden"
};
