import { NavigationTranslations } from "./types";

export const enTranslations: NavigationTranslations = {
    solutions: {
        research: "Research",
        analytics: "Analytics"
    },
    support: {
        pricing: "Pricing",
        documentation: "Documentation",
        guides: "Guides"
    },
    company: {
        about: "About",
        blog: "Blog",
        press: "Press",
        partners: "Partners"
    },
    legal: {
        claim: "Claim",
        privacy: "Privacy",
        terms: "Terms"
    },
    social: {
        twitter: "Twitter"
    }
};
