import styled from "@emotion/styled";

export const StyledListbox = styled.div`
    position: relative;
    user-select: none;
    font-family: "Helvetica", "Arial", sans-serif;
    font-size: 1.25em;
`;

export const StyledButton = styled.button<{ appearance?: "game" }>`
    display: flex;
    border-radius: 0.5em;
    gap: 0.5rem;
    width: 100%;
    background-color: transparent;
    cursor: pointer;
    border: 1px solid transparent;
    &:hover {
        border-color: rgba(
            ${(props) =>
                props.appearance === "game" ? "255, 255, 255" : "0, 0, 0"},
            0.25
        );
    }
    padding: 0.25rem 0.5rem;
    align-items: center;
    appearance: none;
`;

export const LanguageSpan = styled.span<{ appearance?: "game" }>`
    text-transform: uppercase;
    font-size: 1em;
    color: ${(props) => (props.appearance === "game" ? "white" : "#515456")};
`;

export const FlagContainer = styled.div`
    border-radius: 9999px;
    width: 1.275em;
    font-size: 1.5em;
    height: 1.275em;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const FlagImage = styled.img`
    transform: scale(0.9);
`;

export const StyledOptions = styled.div`
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: 0.25rem;
    max-height: 15rem;
    min-width: fit-content;
    width: 100%;
    overflow: auto;
    border-radius: 0.375rem;
    background-color: white;

    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.1);
    outline: none;
    z-index: 50;
`;

export const StyledOption = styled.div<{ active: boolean }>`
    position: relative;
    display: flex;
    flex-direction: row;
    cursor: default;
    select: none;
    padding: 0.25em 0.5em;
    color: #515456;
    background-color: ${(props) =>
        props.active ? "slate-100" : "transparent"};
    &:hover {
        background-color: #f0f0f0;
    }
`;

export const OptionContent = styled.div<{ selected: boolean }>`
    display: flex;
    align-items: center;
    gap: 0.625rem;
    text-transform: uppercase;
    font-size: 0.6375em;
    font-weight: ${(props) => (props.selected ? "bold" : "medium")};
`;

export const CheckIconContainer = styled.span`
    display: flex;
    align-items: center;
    padding-left: 0.75rem;
    color: #515456;
    font-size: 0.8em;
`;
