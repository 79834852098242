import { ProductOverviewTranslations } from './types';

export const deTranslations: ProductOverviewTranslations = {
    title: {
        companyName: "ist ein Produkt von Yellow Bird Consulting"
    },
    description: "Caroline Morton (Allgemeinärztin und Epidemiologin, die Softwareentwicklerin wurde), Maxwell Flitton (Notfallpfleger, der über die Physik zur Softwareentwicklung kam) und Toby Raupach (Kardiologe und Leiter der medizinischen Ausbildung) entwickelten Clinical Metrics als Antwort auf ihre Erfahrungen mit unzureichendem medizinischem Simulationstraining.",
    buttons: {
        readMore: "Mehr lesen",
        website: "yellowbirdconsulting.co.uk"
    }
};