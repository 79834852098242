export const ProfileDropdownProps = {
  default: {
    navigation: [
      {
        href: "/profile",
        name: "Profile",
      },
      {
        href: "/badges",
        name: "Badges",
      },
      {
        href: "",
        name: "Logout",
        
      },
    ],
  },
};
