// External Imports
import React from "react";

import WebsiteButton from "../WebsiteButton";
import { useReactToPrint } from 'react-to-print';

/**
 * Props for the Print component
 */
interface PrintButtonProps {
    printableSection: () => any;
}

export const PrintButton: React.FC<PrintButtonProps> = ({ printableSection }) => {
    const handlePrint = useReactToPrint({
        //@ts-ignore
        content: () => printableSection.current,
    });

    return (
        <WebsiteButton tag="button" onClick={handlePrint}>
            Print
        </WebsiteButton>
    );
}

export default PrintButton;
