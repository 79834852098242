import styled from "@emotion/styled";

export const PillStyle = styled.div<{
    colorHex?: string;
    backgroundColorHex?: string;
}>`
    display: inline-flex;
    width: 150px;
    justify-content: center;
    font-weight: 500;
    color: ${(props) => props.colorHex ?? "#374151"};
    background-color: ${(props) => props.backgroundColorHex ?? "#E5E7EB"};
    text-transform: capitalize;
    padding: 0.55rem 1.125rem;
    border-radius: 0.375rem;
    font-size: 0.9em;
    font-weight: 600;
    color: rgb(255, 255, 255);
    background-color: rgb(22 59 93);
    text-decoration: none;
    font-family: Helvetica, Arial, sans-serif;
`;