import styled from "@emotion/styled";
import { WebsiteButtonProps } from "./WebsiteButton";

export const StyledButton = styled.button<WebsiteButtonProps<any>>`
    font-size: 0.938rem;
    white-space: nowrap;
    font-family: "Helvetica", "Arial", sans-serif;
    padding: 0.6em 1.3em;
    border-radius: 0.8em;
    cursor: pointer;
    display: inline-flex;

    appearance: none;
    ${(props) => (props.disabled ? "pointer-events: none; opacity: 0.5;" : "")}

    align-items: center;
    justify-content: center;
    gap: 0.5em;
    font-weight: 500;
    width: fit-content;
    svg {
        width: 1em;
        height: 1em;
    }
    ${(props) =>
        props.outline
            ? "border: 1px solid #163B5D; background-color: transparent; color: #163B5D; &:hover { color: white; background-color: #163B5D; }"
            : props.secondary
            ? "border: none; background-color: #CAD7E4; color: black; &:hover { background-color: #9CA3AF; color: white; }"
            : props.gameNavBar
            ? `background: #0584C7; color: white; border: none; &:hover { background: #0A6EBD; }`
            : "border: none; background-color: #163B5D; color: white;"}
`;

export const StyledAnchor = StyledButton.withComponent("a");
