import { UsersPageTranslations } from './types';

export const enTranslations: UsersPageTranslations = {
    title: "Users",
    columns: {
        firstName: "First Name",
        lastName: "Last Name",
        email: "Email",
        role: "Role",
        licenseKey: "License Key",
        actions: "Actions",
    },
    buttons: {
        changeRole: "Change Role",
        resetPassword: "Reset Password",
        removeLicenseKey: "Remove License Key",
        addNewLicenseKey: "Add License Key"
    },
    noRecordText: "No users yet",
    copyConfirmation: "Copied!"
};