import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  FormContainer,
  FormGroup,
  Input,
  Label,
} from "./FormComponent.styles";

// Language Context
import { useLanguage } from "../../../core/translations/LanguageContext";

// Translations
import { LoginPageTranslations } from "../translations/types";
import { enTranslations } from "../translations/en";
import { deTranslations } from "../translations/de";
import { SupportedLanguage } from "../../../core/translations/supportedLanguages";

const TRANSLATIONS: Record<SupportedLanguage, LoginPageTranslations> = {
  en: enTranslations,
  de: deTranslations,
};

export interface FormData {
  email: string;
  password: string;
  success: boolean;
  failure: boolean;
  error: string;
  loading: boolean;
}

export interface LoginPageFormProps {
  onSubmit: (data: FormData) => void;
  initialFormData?: FormData;
}

export const LoginForm: React.FC<LoginPageFormProps> = ({
  onSubmit,
  initialFormData,
}) => {
  const navigate = useNavigate();
  const { language } = useLanguage();
  //@ts-ignore
  const translations = TRANSLATIONS[language];
  const [formData, setFormData] = useState<FormData>(
    initialFormData || {
      email: "",
      password: "",
      success: false,
      failure: false,
      error: "",
      loading: false,
    }
  );
  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {
    setFormData(initialFormData || formData);
  }, [initialFormData]);

  const [formClass, setFormClass] = useState("");

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formData.email === "") {
      setFormData({
        ...formData,
        success: false,
        failure: true,
        error: "Please fill in email",
      });
      return null;
    } else if (formData.password === "") {
      setFormData({
        ...formData,
        success: false,
        failure: true,
        error: "Please fill in password",
      });
      return null;
    }
    onSubmit(formData);
  };

  return (
    <FormContainer>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label>{translations.labels.email}</Label>
          <Input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>{translations.labels.password}</Label>
          <Input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
        </FormGroup>
        <Button type="submit">{translations.labels.loginButton}</Button>
      </Form>
    </FormContainer>
  );
};
