import styled from "@emotion/styled";

export const PageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 4em 0;
    min-height: 500px;
    gap: 25px;
    @media (min-width: 1024px) {
        flex-direction: column;
        max-width: 1536px;
        padding: 0 3rem;
        margin: auto;
    }
`;

export const Title = styled.h1`
    font-size: 100px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #135185;
    span{
        color: red;
    }
    @media (min-width: 640px) {
        font-size: 100px;
        line-height: 48px;
        color: #002147;
        font-weight: 700;
    }
`;

export const Paragraph = styled.p`
    font-family: "Helvetica", "Arial", sans-serif;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
`;

export const GoHomeButton = styled.a`
    background-color: #135185;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    padding: 10px;
    font-size: 16px;
    color: white;
    text-decoration: none;
`
