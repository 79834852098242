import React, { lazy } from "react";
import { Td } from "./TableRow.style";

interface TableRowProps {
    item: any;
    tableColumns: any[];
    enableReviewModal?: boolean;
}

const TableRow: React.FC<TableRowProps> = ({
                                               item,
                                               tableColumns,
                                               enableReviewModal,
                                           }) => {
    const [dischargeModalOpen, setDischargeModalOpen] =
        React.useState<boolean>(false);

    return (
        <tr>
            {tableColumns.map((column, index) => (
                <React.Fragment key={index}>
                    <Td>
                        {column.render
                            ? column.render(item)
                            : item[column.key!]}
                    </Td>
                </React.Fragment>
            ))}
        </tr>
    );
};

export default TableRow;
