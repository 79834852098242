import { FooterTranslations } from "./types";

export const deTranslations: FooterTranslations = {
    footer: "Fußzeile",
    tagline: "Bessere Ausbildung bedeutet bessere Ärzt:innen - und damit auch besser behandelte Patient:innen.",
    copyright: "©️ 2024 Yellow Bird Consulting. Alle Rechte vorbehalten.",
    sections: {
        solutions: "Funktionen",
        support: "Informationen",
        company: "Unternehmen",
        legal: "Rechtliches"
    }
};