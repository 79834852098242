import styled from "@emotion/styled";

export const FormContainer = styled.div`

`;

export const Form = styled.form`
    font-family: "Helvetica", "Arial", sans-serif;
    max-width: 400px;
    padding-top: 20px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
`;

// Div container for form elements
export const FormGroup = styled.div`
  width: 100%;
`;

// Label style
export const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  font-size: 15px;
`;

// Input field style (for text, email, password)
export const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Ensures padding doesn't affect overall width */
  font-size: 15px;
  min-width: 240px;

  &:hover {
    background-color: #f2f2f2;
  }
`;

// Select dropdown style
export const Select = styled.select`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Ensures padding doesn't affect overall width */
  font-size: 15px;
`;

// Button style
export const Button = styled.button`
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #0056b3;
  }
`;

// Error message style
export const ErrorText = styled.p`
  outline: none;
  color: red;
  text-align: center;
`;

// Success message style
export const SuccessClass = styled.div`
  text-align: center;
`;

// Success message text
export const SuccessMessage = styled.p`
  color: green;
  font-weight: bold;
`;

// Container for first name and last name fields
export const FirstLastNameContainer = styled.div`
  display: flex;
  gap: 1rem; /* Space between fields */
  width: 100%;
`;

// Individual field container inside first/last name
export const FieldContainer = styled.div`
  flex: 1; /* Each child div takes up equal space */
`;

// Animation for shaking the form when there is an error
export const JiggleAnimation = styled.div`
  animation: jiggle 0.5s ease-in-out;

  @keyframes jiggle {
    0% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(-5px);
    }
    50% {
      transform: translateY(5px);
    }
    75% {
      transform: translateY(-5px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

// Anchor link style for "get started" and "or" sections
export const GetStartedAnchor = styled.a`
  text-decoration: none;
`;

export const OrSection = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  min-width: 240px;
  max-width: 400px;
`;

export const OnSectionAnchor = styled.a`
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  font-size: 16px;
`;
