import { ChangeRoleModalTranslations } from './types';

export const deTranslations: ChangeRoleModalTranslations = {
    title: "Rolle ändern",
    options: [
        "Student",
        "Lehrer",
        "Admin"
    ],
    submit: "Absenden"
};
