import type { ImpressumContentModal } from "./types";

export const deTranslations: ImpressumContentModal = {
    title: "Impressum",
    popupWidth: "61em",

    subTitle: "Firmeninformation",
    summary: "Dieses Produkt wurde von Yellow Bird Consulting Ltd. erstellt.",
    address: {
        title: "Adresse",
        detail: "Yellow Bird Consulting Ltd. 2 Underwood Row. London. N1 7LQ. Großbritannien",
    },
    companyDetail: {
        title: "Angaben zur Firmenregistrierung",
        info: [
            "Company Number: 11772572",
            "VAT Number: GB3521433321",
            "Zugelassen in England und Wales",
        ],
    },
    contactDetail: {
        title: "Contact Information",
        email: "admin@yellowbirdconsulting.co.uk",
    },
    disclaimer: {
        title: "Haftungsausschluss",
        sections: [
            {
                point: "1. Inhalt des Onlineangebotes",
                pointDetail: `Yellow Bird Consulting Ltd übernimmt keinerlei Gewähr für die
            Aktualität, Korrektheit, Vollständigkeit oder Qualität der
            bereitgestellten Informationen. Haftungsansprüche gegen den Autor,
            welche sich auf Schäden materieller oder ideeller Art beziehen, die
            durch die Nutzung oder Nichtnutzung der dargebotenen Informationen
            bzw. durch die Nutzung fehlerhafter und unvollständiger
            Informationen verursacht wurden, sind grundsätzlich ausgeschlossen,
            sofern seitens Yellow Bird Consulting Ltd kein nachweislich
            vorsätzliches oder grob fahrlässiges Verschulden vorliegt. Alle
            Angebote sind freibleibend und unverbindlich. Die Yellow Bird
            Consulting Ltd behält es sich ausdrücklich vor, Teile der Seiten
            oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern,
            zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder
            endgültig einzustellen.`,
            },
            {
                point: "2. Verweise und Links",
                pointDetail: `Bei direkten oder indirekten Verweisen auf fremde Internetseiten
            ("Links"), die außerhalb des Verantwortungsbereiches von Yellow Bird
            Consulting Ltd liegen, würde eine Haftungsverpflichtung
            ausschließlich in dem Fall in Kraft treten, in dem der Autor von den
            Inhalten Kenntnis hat und es ihm technisch möglich und zumutbar
            wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern. Die
            Yellow Bird Consulting Ltd erklärt hiermit ausdrücklich, dass zum
            Zeitpunkt der Linksetzung die entsprechenden verlinkten Seiten frei
            von illegalen Inhalten waren. Auf die aktuelle und zukünftige
            Gestaltung, die Inhalte oder die Urheberschaft der
            gelinkten/verknüpften Seiten hat Yellow Bird Consulting Ltd
            keinerlei Einfluss. Deshalb distanziert sie sich hiermit
            ausdrücklich von allen Inhalten aller gelinkten/verknüpften Seiten,
            die nach der Linksetzung verändert wurden. Diese Feststellung gilt
            für alle innerhalb des eigenen Internetangebotes gesetzten Links und
            Verweise sowie für Fremdeinträge in von Yellow Bird Consulting Ltd
            eingerichteten Gästebüchern, Diskussionsforen und Mailinglisten. Für
            illegale, fehlerhafte oder unvollständige Inhalte und insbesondere
            für Schäden, die aus der Nutzung oder Nichtnutzung solcherart
            dargebotener Informationen entstehen, haftet allein der Anbieter der
            Seite, auf welche verwiesen wurde, nicht derjenige, der über Links
            auf die jeweilige Veröffentlichung lediglich verweist.`,
            },
            {
                point: "3. Urheber- und Kennzeichenrecht",
                pointDetail: `Die Yellow Bird Consulting Ltd ist bestrebt, in allen Publikationen
            die Urheberrechte der verwendeten Grafiken, Tondokumente,
            Videosequenzen und Texte zu beachten, von ihr selbst erstellte
            Grafiken, Tondokumente, Videosequenzen und Texte zu nutzen oder auf
            lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte
            zurückzugreifen. Alle innerhalb des Internetangebotes genannten und
            ggf. durch Dritte geschützten Marken- und Warenzeichen unterliegen
            uneingeschränkt den Bestimmungen des jeweils gültigen
            Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen
            Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluss
            zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt
            sind. Das Copyright für veröffentlichte, von Yellow Bird Consulting
            Ltd selbst erstellte Objekte bleibt allein bei Yellow Bird
            Consulting Ltd. Eine Vervielfältigung oder Verwendung solcher
            Grafiken, Tondokumente, Videosequenzen und Texte in anderen
            elektronischen oder gedruckten Publikationen ist ohne ausdrückliche
            Zustimmung der Yellow Bird Consulting Ltd nicht gestattet.`,
            },
            {
                point: "4. Datenschutz",
                pointDetail: `Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe
            persönlicher oder geschäftlicher Daten (Emailadressen, Namen,
            Anschriften) besteht, so erfolgt die Preisgabe dieser Daten seitens
            des Nutzers auf ausdrücklich freiwilliger Basis. Die Inanspruchnahme
            und Bezahlung aller angebotenen Dienste ist - soweit technisch
            möglich und zumutbar - auch ohne Angabe solcher Daten bzw. unter
            Angabe anonymisierter Daten oder eines Pseudonyms gestattet.`,
            },
            {
                point: "5. Rechtswirksamkeit dieses Haftungsausschlusses",
                pointDetail: `Sofern Teile oder einzelne Formulierungen dieses Textes der
            geltenden Rechtslage nicht, nicht mehr oder nicht vollständig
            entsprechen sollten, bleiben die übrigen Teile des Dokumentes in
            ihrem Inhalt und ihrer Gültigkeit davon unberührt.`,
            },
        ],
    },
};
