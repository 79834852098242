import styled from "@emotion/styled";

export const ResetPasswordModalContainer = styled.div`
    width: 400px;
    gap: 1rem;
    color: #000000;
    position: relative;
    padding: 2rem 1.5rem;
    border-radius: 0.75rem;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
`;

export const ResetPasswordModalContentContainer = styled.div`
    overflow-y: auto;
    max-height: 35em;
`;

export const ResetPasswordModalTitle = styled.h1`
    font-size: 22px;
    margin-top: 5px;
`;

export const ResetPasswordModalContentWrapper = styled.div`
    margin-top: 1rem;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

export const SubTitle = styled.p`
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    text-align: center;
`;

export const Button = styled.button`
    width: 150px;
    text-transform: capitalize;
    padding: 0.75rem 1.125rem;
    border-radius: 0.375rem;
    font-size: 0.9em;
    font-weight: 600;
    color: rgb(255, 255, 255);
    background-color: rgb(22 59 93);
    text-decoration: none;
    font-family: Helvetica, Arial, sans-serif;
    border: navajowhite;
`;

