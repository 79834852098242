import { ChangeRoleModalTranslations } from './types';

export const enTranslations: ChangeRoleModalTranslations = {
    title: "Change Role",
    options: [
        "Student",
        "Teacher",
        "Admin"
    ],
    submit: "Submit"
};