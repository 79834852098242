import styled from "@emotion/styled";
import { FaPlay } from "react-icons/fa";

export const PageContainer = styled.div`
  width: 100%;
`;
export const Section = styled.div``;
export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  position: relative;
  padding: 4rem 0;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;
export const TextContainer = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  @media (min-width: 1024px) {
    width: 40%;
  }
`;
export const Title = styled.h1`
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5em;
  max-width: 20em;
  color: #1a4064;

  @media (min-width: 640px) {
    font-size: 1.25rem;
  }

  @media (min-width: 1024px) {
    font-size: 1.5rem;
  }
  svg {
    margin-right: 0.5em;
  }
`;
export const Paragraph = styled.p`
  margin-top: 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  text-color: #0369a1;
  line-height: 2rem;
  max-width: 33em;
`;
export const ButtonContainer = styled.div`
  margin-top: 2.5rem;
  max-width: 100%;
  display: flex;
  justify-content: start;
  gap: 0.75rem;
`;
export const VisionSection = styled.div`
  padding-top: 4rem;
  width: 100%;
  position: relative;
`;
export const VisionBackground = styled.div`
  background-color: #414c5a;
  width: 200vw;
  position: absolute;
  left: -100vw;
  top: 0;
  bottom: 0;
`;
export const VisionTextContainer = styled.div`
  width: 100%;
  text-align: left;

  @media (min-width: 1024px) {
    width: 40%;
  }
`;
export const VisionTitle = styled.h2`
  font-size: 1.1em;
  font-weight: 800;
  color: #9fb1c2;
`;
export const VisionParagraph = styled.p`
  margin-top: 1.5rem;
  font-size: 0.95em;
  font-weight: 400;
  color: white;
  line-height: 2rem;
  max-width: 33em;
`;
export const VideoContainer = styled.div`
  margin-top: 2.5rem;
  display: block;
  text-align: center;
  position: relative;

  @media (min-width: 1024px) {
    width: 60%;
  }

  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
  }

  .play-button {
    transition: all 0.3s ease-in-out;
  }

  img {
    width: 15em;
    border-radius: 0.5rem;
    @media (min-width: 1024px) {
      width: 40em;
    }
  }
  > div {
    position: relative;
    display: inline-block;
  }
`;
export const VideoTitle = styled.p`
  margin-top: 1.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: white;
  text-align: center;
`;
export const VideoButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }

  &:hover {
    transform: scale(1.05);
  }
`;

export const VideoImg = styled.img`
  transition: all 0.3s ease-in-out;
`;

export const PlayButton = styled(FaPlay)`
  position: absolute;
  inset: 0;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    color: white;
    font-size: 2rem;
  }
`;
