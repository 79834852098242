import { NotFoundTranslations } from './types';

export const enTranslations: NotFoundTranslations = {
    title: {
        prefix: "4",
        middle: "0",
        suffix: "4"
    },
    messages: {
        pageNotExist: "Looks like this page does not exist.",
        goBack: "Go back to home and continue exploring."
    },
    button: {
        text: "Back to Home"
    }
};