import styled from "@emotion/styled";

export const StyledFooter = styled.footer`
  background-color: #e0f2fe;
  color: #4a5568;
  margin-top: 0;
  font-size: 0.95em;
  a {
    text-decoration: none;
    color: #475569;
  }
`;
export const HiddenHeading = styled.h2`
  display: none;
`;
export const Container = styled.div`
  max-width: 80rem;
  font-family: "Helvetica", Arial, sans-serif;
  margin: auto;
  padding: 1rem 2rem;
  @media (min-width: 640px) {
    padding: 1rem 2rem;
  }
  @media (min-width: 1024px) {
    padding: 1rem 2rem;
  }
`;
export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  @media (min-width: 768px) {
    flex-direction: row;
    gap: 0;
  }
`;
export const FlexItem = styled.div`
  margin-right: auto;
`;
export const Paragraph = styled.p`
  max-width: 261px;
  line-height: 1.5em;
`;
export const SocialLinks = styled.div`
  margin-top: 2.25rem;
  display: flex;
  gap: 26px;
  font-size: 0.9em;
`;
export const LinkGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3.5rem;
  @media (min-width: 1024px) {
    display: flex;
    gap: 7rem;
  }
  font-size: 0.95em;
`;
export const FooterBottom = styled.div`
  margin-top: 5rem;
  padding: 0.5rem 0;
  border-top: 1px solid #00000022;
  position: relative;
  svg {
    position: absolute;
    top: -43px;
    right: 0;
  }
`;
export const Copyright = styled.p`
  text-align: center;
  color: #4c4f51;
  @media (min-width: 1280px) {
    text-align: center;
  }
  font-size: 0.875rem;
`;
