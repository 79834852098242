// External imports
import React, { useState } from "react";
import { ContentWrapper, CopyText, CopyTextSpan } from "./CopyLicenceKey.style";
import { FaCopy } from "react-icons/fa";
import { useLanguage } from "../../../../core/translations/LanguageContext";
import { SupportedLanguage } from "../../../../core/translations/supportedLanguages";
import { UsersPageTranslations } from "../../translations/types";
import { enTranslations } from "../../translations/en";
import { deTranslations } from "../../translations/de";

// Interface for the user details
interface iUser {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  licenseKey: string;
}

/**
 * CopyLicenceKeyProp.
 *
 * This is the props interface for the CopyLicenceKey component.
 */
export interface CopyLicenceKeyProps {
  row: iUser;
}
const TRANSLATIONS: Record<SupportedLanguage, UsersPageTranslations> = {
  en: enTranslations,
  de: deTranslations,
};

const COPY_CONFIRMATION_TIMEOUT = 5000;

/**
 * CopyLicenceKey Component
 *
 * This component is used to display the discharge patient details table, showing the patients
 * who has been discharged from the department, their diagnosis, and their final "score".
 *
 * @param data - The patient data to display in the table.
 */
export const CopyLicenceKey: React.FC<CopyLicenceKeyProps> = ({ row }) => {
  const { language } = useLanguage();
  //@ts-ignore
  const translations = TRANSLATIONS[language];
  const [isCopied, setIsCopied] = useState(false);

  /**
   * Handles copying the license key to clipboard
   * Shows a confirmation message for 2 seconds
   */
  const handleCopyClick = async (key: string): Promise<void> => {
    try {
      await navigator.clipboard.writeText(key);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, COPY_CONFIRMATION_TIMEOUT);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <>
      <ContentWrapper>
        <CopyText>{row.licenseKey}</CopyText>
        <FaCopy
          onClick={() => handleCopyClick(row.licenseKey)}
          role="button"
          aria-label="Copy code"
          style={{ cursor: "pointer" }}
        />
      </ContentWrapper>
      {isCopied && <CopyTextSpan>{translations.copyConfirmation}</CopyTextSpan>}
    </>
  );
};

export default CopyLicenceKey;
