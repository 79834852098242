export const FooterSocialIcon = ({
  Icon,
  ...rest
}: {
  Icon: React.ComponentType<React.ComponentProps<"svg">>;
}) => (
  <Icon
    style={{ height: "1.6em", width: "auto" }}
    aria-hidden="true"
    {...rest}
  />
);
