import styled from "@emotion/styled";

export const TermsModalContainer = styled.div`
    width: 61em;
    gap: 1rem;
    color: #000000;
    position: relative;
    padding: 2rem 1.5rem;
    border-radius: 0.75rem;
    background-color: #ffffff;
    margin-bottom:15%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    font-family: "Helvetica", Arial, sans-serif;
`;

export const TermsModalContentContainer = styled.div`
overflow-y: auto;
    max-height: 35em;
    @media (max-width: 1023px) {
       padding-bottom:10%;
    }
`;

export const TermsModalTitle = styled.h1`
    font-size: 22px;
    margin-top: 5px;
`;
export const TermsModalSubtitle = styled.h2`
    font-size: 20px;
    margin-top: 5px;
`;
export const TermsModalLink = styled.a`
    font-size: 15px;
    color: #0000ee;
    margin-bottom: 20px;
`;

export const TermsModalParagraph = styled.p`
    font-size: 15px;
    font-weight: 400;
    margin: 1rem 10px 1rem 0;
    line-height: 1.5;
`;

export const TermsModalBoldText = styled.span`
    font-weight: 700;
`;

export const TermsModalUnorderList = styled.ul`
    line-height: 1.5;
`;

export const TermsModalList = styled.li`
    font-size: 15px;
    line-height: 1.5;
    font-weight: 400;
`;

export const TermsModalBtns = styled.div`
    display:flex;
    gap:10px;
`;

export const PrintableArea = styled.div`
    padding: 15px;
`;
