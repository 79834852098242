import { useState } from "react";
import { FaUser } from "react-icons/fa";
import {
    ProfileDropdownButton,
    ProfileDropdownContainer,
    ProfileDropdownMenu,
    ProfileDropdownMenuItem,
} from "./ProfileDropdown.styles";
import { OutsideClickHandler } from "../modal/OutsideClickHandler";

interface ProfileDropdownProps {
    navigation: {
        href: string;
        name: string;
        onClick?: () => void;
    }[];
}

export function ProfileDropdown({ navigation }: ProfileDropdownProps) {
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <OutsideClickHandler onOutsideClick={() => setMenuOpen(false)}>
            <ProfileDropdownContainer menuOpen={menuOpen}>
                <ProfileDropdownButton onClick={() => setMenuOpen(!menuOpen)}>
                    <FaUser size={25} />
                </ProfileDropdownButton>
                {menuOpen && (
                    <ProfileDropdownMenu>
                        {navigation.map((item) => (
                    item?.onClick ? 

                            <ProfileDropdownMenuItem key={item.href} onClick={() => item?.onClick && item?.onClick()}>
                                {item.name}
                            </ProfileDropdownMenuItem>
                            : 
                            <ProfileDropdownMenuItem key={item.href} onClick={() => item?.onClick && item?.onClick()}>
                                <a href={item.href}>{item.name}</a>
                            </ProfileDropdownMenuItem>
                        ))}
                    </ProfileDropdownMenu>
                )}
            </ProfileDropdownContainer>
        </OutsideClickHandler>
    );
}

export default ProfileDropdown;
