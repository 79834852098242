import styled from "@emotion/styled";

export const TableCard = styled.div`
    background: white;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px;
    padding: 1.5rem;
    margin: 1rem auto;
    width: 100%;
    max-width: 1400px;
`;

export const PageTitleSection = styled.div`
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-bottom: 2rem;
    padding: 1rem 0px;
`;

export const PageTitle = styled.h1`
    font-size: 1.5rem;
    margin: 0px;
`;

export const ActionsCard = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

export const ActionButtons = styled.button<{
    backgroundColorHex?: string;
}>`
    width: 150px;
    text-transform: capitalize;
    padding: 0.55rem 1.125rem;
    border-radius: 0.375rem;
    font-size: 0.9em;
    font-weight: 600;
    color: rgb(255, 255, 255);
    background-color: ${(props) => props.backgroundColorHex ?? "rgb(22 59 93)"};
    text-decoration: none;
    font-family: Helvetica, Arial, sans-serif;
    border: navajowhite;
`;