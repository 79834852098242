import { FrontPageTranslations } from "./types";

export const deTranslations: FrontPageTranslations = {
    simulationTraining: "Simulationstraining für",
    medicalProfessionals: "Medizinische Notfälle",
    description: "Herzlich willkommen in unserer virtuellen Notaufnahme. Hier können Sie Ihre Fertigkeiten in der Triage, der Differentialdiagnose und der Initialtherapie akut erkrankter Patienten:innen trainieren. Es steht eine unbegrenzte Zahl virtueller Patient:innen zur Verfügung, und Sie erhalten Feedback zu Ihren Aktivitäten. Legen Sie gleich los.",
    imageAlt: "Medizinische Simulation",
    buttons: {
        signIn: "Login",
        bookDemo: "Demo buchen",
        manageClasses: "Klassen verwalten",
        joinClass: "Einer Klasse beitreten"
    }
};