import { ContactTranslations } from "./types";

export const enTranslations: ContactTranslations = {
    title: "Get in touch",
    description: "If you have a question or would like to get in touch, please send us a message using the form below",
    form: {
        name: "Name",
        email: "Email",
        helpMessage: "How can we help you?",
        charLimit: "Max 500 characters",
        submit: "Send",
        thankYou: "Thanks! We'll be in touch soon."
    }
};
