import React from "react";
import { FaLock, FaLockOpen } from "react-icons/fa";

import WebsiteButton from "../../../components/button/WebsiteButton";
import MainPageLayout from "../../../components/header/MainPageLayout";

import Table from "../../../components/table/Table";
import Pill from "../components/Pill";
import {
  TableCard,
  PageTitleSection,
  PageTitle,
  ActionsCard,
  ActionButtons,
} from "./InstitutionsTable.styles";

interface Institution {
  institutionName: string;
  numberOfLicense: string;
  activeUser: string;
  status: "active" | "inactive" | "free trial";
}

interface InstitutionTableProps {
  data?: Institution[];
  columns?: Array<{
    key: string;
    label: string;
    render?: (value: any) => React.ReactNode;
  }>;
}

// Default columns configuration
const defaultColumns = [
  {
    key: "institutionName",
    accessor: "institutionName",
    label: "Institution Name",
  },
  {
    key: "status",
    accessor: "status",
    label: "Status",
    render: (row: any) => <Pill>{row?.status}</Pill>,
  },
  {
    key: "numberOfLicense",
    accessor: "numberOfLicense",
    label: "No. of licneses",
  },
  {
    key: "activeUser",
    accessor: "activeUser",
    label: "No. of Active Users",
  },
  
  {
    key: "actions",
    accessor: "actions",
    label: "Actions",
    render: (row: any) => {
      return (
        <ActionsCard>
          <ActionButtons backgroundColorHex="#278230">Buy More Licenses</ActionButtons>
          <ActionButtons backgroundColorHex="#cc0000">View Users</ActionButtons>
          <ActionButtons>Assign Licenses</ActionButtons>
        </ActionsCard>
      );
    },
  },
];

// Sample data
export const fakeInstitutions: Institution[] = [
  {
    institutionName: "PE",
    numberOfLicense: "5",
    activeUser: "10",
    status: "active",
  },
  {
    institutionName: "Cardio",
    numberOfLicense: "5",
    activeUser: "10",
    status: "inactive",
  },
  {
    institutionName: "PE",
    numberOfLicense: "5",
    activeUser: "10",
    status: "free trial",
  },
  {
    institutionName: "PE",
    numberOfLicense: "5",
    activeUser: "10",
    status: "active",
  },
  {
    institutionName: "Cardio",
    numberOfLicense: "5",
    activeUser: "10",
    status: "inactive",
  },
  {
    institutionName: "PE",
    numberOfLicense: "5",
    activeUser: "10",
    status: "free trial",
  },
  {
    institutionName: "PE",
    numberOfLicense: "5",
    activeUser: "10",
    status: "active",
  },
  {
    institutionName: "Cardio",
    numberOfLicense: "5",
    activeUser: "10",
    status: "inactive",
  },
  {
    institutionName: "PE",
    numberOfLicense: "5",
    activeUser: "10",
    status: "free trial",
  },
];

function InstitutionTable({
  data = fakeInstitutions,
  columns = defaultColumns,
}: InstitutionTableProps) {
  return (
    <MainPageLayout>
      <TableCard>
        <PageTitleSection>
          <PageTitle>Institutions</PageTitle>
        </PageTitleSection>
        <Table
          data={data}
          columns={defaultColumns}
          noRowsText="No institutions found"
        />
      </TableCard>
    </MainPageLayout>
  );
}

export default InstitutionTable;
