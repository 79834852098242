// External Imports
import React from 'react';

// Defines the main pages routes
import NotFoundPage from "./not-found-page/NotFoundPage";
import ViewUsersPage from "./viewUsersPage/ViewUsersPage";
import InstitutionsTable from "./viewInstitutionsPage/components/InstitutionsTable";


export const MainPageRoutes = [
    {
        path: "/institutions",
        element: <InstitutionsTable
        />,
    },
    {
        path: "/users",
        element: <ViewUsersPage data={[]}/>
    },
    {
        path: "/user",
        element: <NotFoundPage
        />,
    },
]
