import styled from "@emotion/styled";

export const MainPageGlobalStyles = styled.div`
  background-color: #e0f2fe;
  font-family: "Helvetica", "Arial", sans-serif;
  .sb-show-main sb-main-padded {
    margin: 0;
  }
  &,
  * {
    box-sizing: border-box;
  }
`;

export const MainPageContent = styled.div`
  padding-top: 3em;
  padding-bottom: 1em;
  padding-left: 2rem;
  padding-right: 2rem;
  overflow-x: hidden;
`;
