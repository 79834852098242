import { VisionTranslations } from './types';

export const deTranslations: VisionTranslations = {
    title: "UNSERE VISION",
    paragraphs: {
        challenge: "Als Mediziner stellten wir fest, dass begrenzte Szenarien und mangelnde Anpassungsfähigkeit die Effektivität traditioneller Simulationstrainings einschränkten. Wir erfuhren aus erster Hand, wie unzureichendes Feedback die Gesundheitsfachkräfte nicht angemessen auf die Realität der Praxis vorbereitete.",
        belief: "Wir waren überzeugt, dass Simulationstraining klinische Fähigkeiten und Entscheidungsfindung durch kollaboratives Lernen verbessern sollte - aber die meisten Plattformen konnten dies nicht bieten.",
        solution: "Wir haben Clinical Metrics entwickelt, um diese Herausforderungen anzugehen und einen dynamischen, adaptiven und datengesteuerten Ansatz für medizinisches Simulationstraining zu bieten. Sehen Sie sich Beispiele unserer realistischen medizinischen Simulationen in unseren Videos an."
    },
    video: {
        title: "Sehen Sie unser Einführungsvideo",
        alt: "Unsere Vision"
    }
};